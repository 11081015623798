import React, { useState, useContext, useEffect, useRef } from "react";
import { Typography } from '@mui/material'
import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSize } from "../../../Utils/utils";

export default function GraphCurso({ mes, anio, curso, dataGraficaXcurso }) {

    const [options, setOptions] = useState({
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.parsed.y + '%';
                    },
                },
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                color: '#000',
                font: {
                    size: "9px",
                    weight: 'bold',
                },
                formatter: (value, context) => {
                    return value + "%";
                }
            },
        },
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: { color: '#000000' },
            },
            x: {
                ticks: { color: 'black' }
            }
        }
    });

    var suma = 0;
    for (let totalAsistenciaAlumno of dataGraficaXcurso.datasets[0].data) {
        suma += parseFloat(totalAsistenciaAlumno);
    }

    var promedio_asistencia_curso = suma / (dataGraficaXcurso.datasets[0].data.length);
    var promedio_asistencia_curso = promedio_asistencia_curso.toFixed(2);

    return (
        <>
            <Typography variant='h6' sx={{ fontSize: fontSize }}>Promedio de Asistencia de Curso, Mes de {mes} {anio} / {curso} / Asistencia {promedio_asistencia_curso}%</Typography>
            <div id="grafica-curso-mes-informe">
                <Bar data={dataGraficaXcurso} options={options} plugins={[ChartDataLabels]} width={100} height={50} />
            </div>
        </>
    )
}
