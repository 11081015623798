import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../../../contexts/IlumenDataContext";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { DataGrid } from "@mui/x-data-grid";

import datagridScroll from '../../../../themes/DataGrid/scroll';

import { ThemeProvider } from '@mui/material/styles';
import { fontSize, fontSizeTable } from "../../../../Utils/utils";

export default function TablaTopCurso({ titulo, info, handleVerCurso }) {
    const tablaTopCurso = [];

    const columns = [
        { field: 'id', headerName: 'ID', width: 30 },
        {
            field: 'asistencia',
            headerName: 'Asistencia',
            width: 70,
            editable: false,
            renderCell: (params) => (
                <>
                    {params.row.asistencia}%
                </>
            ),
        },
        {
            field: 'opciones',
            headerName: '',
            width: 50,
            editable: false,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="Ver Asistencia Alumnos"
                        onClick={() =>
                            handleVerCurso(params.row.id_curso)
                        }
                    >
                        <ZoomInIcon />
                    </IconButton>
                </>
            ),
        },
        {
            field: 'curso',
            headerName: 'Curso',
            width: 200,
            editable: false,
        },


    ];

    const rows = [];

    if (Object.keys(info).length !== 0) {

        info.forEach((item) => {

            let objTopCursos = {
                id: `asistencia-top-${item.id_curso}`,
                id_curso: item.id_curso,
                curso: item.curso,
                asistencia: item.asistencia,

            };

            rows.push(objTopCursos);

        });
    }
    const hiddenColumns = ['id'];
    rows.sort((a, b) => b.asistencia - a.asistencia);

    tablaTopCurso.push(
        <React.Fragment key="tabla-top-asistencia">
            <Card>
                <CardContent>
                    <ThemeProvider theme={datagridScroll}>
                        <Typography variant='h6' sx={{ fontSize: fontSize }}>{titulo}</Typography>
                        <DataGrid
                            autoHeight
                            autoWidth
                            rows={rows}
                            columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            rowHeight={30}
                            columnHeaderHeight={40}
                            sx={{ fontSize: fontSizeTable }}
                        />
                    </ThemeProvider>
                </CardContent>
            </Card>
        </React.Fragment>
    );
    return tablaTopCurso;
}