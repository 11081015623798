import React, { useState, useContext } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { ThemeSelectProfesorJefe } from './ThemeSelectProfesorJefe';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";
import { capitalize } from '../../Utils/utils';

export default function SelectProfesorJefe() {

    const {
        infoProfesorJefe, correoProfesorJefe, setCorreoProfesorJefe,
    } = useContext(IlumenDataContext);

    console.log(infoProfesorJefe)
    const handleChange = (event) => {
        const { target: { value }, } = event;
        console.log(event);
        setCorreoProfesorJefe(
            // En caso de ser un objeto, necesitarías procesar para obtener IDs o nombres
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <React.Fragment key="select-directivo-dialog">
            <ThemeProvider theme={ThemeSelectProfesorJefe}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel variant="standard" htmlFor="select-profesor-jefe">
                        Profesor Jefe
                    </InputLabel>
                    <Select
                        defaultValue=""
                        value={correoProfesorJefe}
                        onChange={handleChange}
                        input={<OutlinedInput label="Profesor jefe" id="select-profesor-jefe" />}
                    >
                        <MenuItem key={`pj-np`} value="">
                            <ListItemText primary="Seleccione Profesor" />
                        </MenuItem>
                        {infoProfesorJefe && (
                            <MenuItem key={`pj-${infoProfesorJefe.id_profesor_jefe}`} value={infoProfesorJefe.email}>
                                <ListItemText primary={capitalize(infoProfesorJefe.nombre)} />
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </ThemeProvider>
        </React.Fragment>
    );
};

