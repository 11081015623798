import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { capitalize, delay } from "../Utils/utils";

import logoIlumenData from "../assets/logo-ilumen-pdf.png";
import { template_curva_asistencia_informe_datos_alumno_curso_mes, template_intro_informe_colegio_mes, template_intro_informe_curso_mes, template_intro_informe_datos_alumno_curso_mes, template_intro_informe_tabla_alumnos_curso_mes, template_localizacion_asistencia_informe_datos_alumno_curso_mes, template_tabla_cursos_informe_colegio_mes, template_tabla_ina_informe_colegio_mes } from "./templatesInforme";

const setFixedDimensions = (element, width, height) => {
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
    element.style.position = 'fixed';
};

const revertDimensions = (element) => {
    element.style.width = '';
    element.style.height = '';
    element.style.position = '';
};

function redimensionarImagen(anchoOriginal, altoOriginal, width) {
    // Calcular el factor de escala para mantener la proporción de aspecto
    const factorEscala = width / anchoOriginal;

    // Calcular el nuevo alto para mantener la proporción de aspecto
    const height = altoOriginal * factorEscala;

    return { width, height };
}

export const generateInformeColegioPDF = async (outputFile, mes, anio, logo_colegio, reporte_informe_general_IA) => {
    //console.log(logo_colegio);
    const elemento_grafica_colegio = document.getElementById('grafica-colegio-general-mes');
    const elemento_tabla_ina_alumno_general = document.getElementById('grafica-tabla-inasistencia-alumno-general-informe');
    const elemento_tabla_low_curso = document.getElementById('grafica-tabla-low-curso-informe');

    var intro_informe = template_intro_informe_colegio_mes(capitalize(outputFile), mes, anio);
    var intro_ina_informe = template_tabla_ina_informe_colegio_mes();
    var intro_promedio_cursos = template_tabla_cursos_informe_colegio_mes();

    const pdf = new jsPDF();
    pdf.lineHeightProportion = 5;
    let imagen_grafica_colegio, imagen_tabla_ina_alumno_general, imagen_tabla_low_curso;
    let ancho_imagen_tabla_ina_alumno_general, alto_imagen_tabla_ina_alumno_general;
    let ancho_imagen_tabla_low_curso, alto_imagen_tabla_low_curso;

    const captureImages = async () => {

        if (window.innerWidth <= 2046) {
            setFixedDimensions(elemento_grafica_colegio, 2046, 800);
        }

        await delay(1000);

        const canvas_grafica_colegio = await html2canvas(elemento_grafica_colegio, { scale: 1.2 });
        imagen_grafica_colegio = canvas_grafica_colegio.toDataURL('image/png');

        const canvas_tabla_ina_alumno_general = await html2canvas(elemento_tabla_ina_alumno_general, { scale: 1.2 });
        imagen_tabla_ina_alumno_general = canvas_tabla_ina_alumno_general.toDataURL('image/png');

        const canvas_tabla_low_curso = await html2canvas(elemento_tabla_low_curso, { scale: 1.2 });
        imagen_tabla_low_curso = canvas_tabla_low_curso.toDataURL('image/png');

        // Obtener el ancho y alto de la imagen
        ancho_imagen_tabla_ina_alumno_general = canvas_tabla_ina_alumno_general.width;
        alto_imagen_tabla_ina_alumno_general = canvas_tabla_ina_alumno_general.height;

        ancho_imagen_tabla_low_curso = canvas_tabla_low_curso.width;
        alto_imagen_tabla_low_curso = canvas_tabla_low_curso.height;

        revertDimensions(elemento_grafica_colegio);
    };

    const addImagesToPDF = () => {
        // hoja #1
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }

        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Contenido del informe", 10, 35);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 45, intro_informe, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_grafica_colegio, 'PNG', 10, 110, 230, 105);

        // hoja #2
        var existe_analsis_cualitativo = reporte_informe_general_IA.analsis_cualitativo;
        console.log(existe_analsis_cualitativo.length);
        if (existe_analsis_cualitativo.length != 0) {
            pdf.addPage();
            if (/base64/.test(logo_colegio)) {
                pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
            }
            pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor("#9a0707");
            pdf.text("Análisis Cualitativo de Datos", 10, 35);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            pdf.setTextColor("#000");
            pdf.text(10, 45, reporte_informe_general_IA.analsis_cualitativo, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })

        }
        // hoja #3
        const resize_imagen_tabla_ina_alumno_general = redimensionarImagen(ancho_imagen_tabla_ina_alumno_general, alto_imagen_tabla_ina_alumno_general, 100);
        pdf.addPage();
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Ranking de Inasistencia de Alumnos", 10, 35);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 45, intro_ina_informe, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_ina_alumno_general, 'PNG', 55, 90, resize_imagen_tabla_ina_alumno_general.width, resize_imagen_tabla_ina_alumno_general.height);

        // hoja #4
        const resize_imagen_tabla_low_curso = redimensionarImagen(ancho_imagen_tabla_low_curso, alto_imagen_tabla_low_curso, 80);
        pdf.addPage();
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Promedio de asistencias de cursos por mes", 10, 35);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 45, intro_promedio_cursos, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_low_curso, 'PNG', 65, 80, resize_imagen_tabla_low_curso.width, resize_imagen_tabla_low_curso.height);

        // hoja #5 conclusion
        var existe_conclusion_recomendacion = reporte_informe_general_IA.conclusion_recomendacion;
        console.log(existe_conclusion_recomendacion.length);
        if (existe_conclusion_recomendacion.length != 0) {

            pdf.addPage();
            if (/base64/.test(logo_colegio)) {
                pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
            }
            pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor("#9a0707");
            pdf.text("Conclusiones y Recomendaciones", 10, 35);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            pdf.setTextColor("#000");
            pdf.text(10, 45, reporte_informe_general_IA.conclusion_recomendacion, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })

        }

        outputFile = outputFile.replace(/\s+/g, "-");
        pdf.save(`informe-${capitalize(outputFile)}-${mes}-${anio}.pdf`);
    };

    await captureImages();
    addImagesToPDF();
    //setIsLoading(false);
};


export const generateInformeColegioPDFEmail = async (outputFile, mes, anio, logo_colegio, reporte_informe_general_IA) => {

    const elemento_grafica_colegio = document.getElementById('grafica-colegio-general-mes');
    const elemento_tabla_ina_alumno_general = document.getElementById('grafica-tabla-inasistencia-alumno-general-informe');
    const elemento_tabla_low_curso = document.getElementById('grafica-tabla-low-curso-informe');

    var intro_informe = template_intro_informe_colegio_mes(capitalize(outputFile), mes, anio);
    var intro_ina_informe = template_tabla_ina_informe_colegio_mes();
    var intro_promedio_cursos = template_tabla_cursos_informe_colegio_mes();

    const pdf = new jsPDF();
    pdf.lineHeightProportion = 5;
    let imagen_grafica_colegio, imagen_tabla_ina_alumno_general, imagen_tabla_low_curso;
    let ancho_imagen_tabla_ina_alumno_general, alto_imagen_tabla_ina_alumno_general;
    let ancho_imagen_tabla_low_curso, alto_imagen_tabla_low_curso;

    const captureImages = async () => {

        if (window.innerWidth <= 2046) {
            setFixedDimensions(elemento_grafica_colegio, 2046, 800);
        }

        await delay(1000);

        const canvas_grafica_colegio = await html2canvas(elemento_grafica_colegio, { scale: 1.2 });
        imagen_grafica_colegio = canvas_grafica_colegio.toDataURL('image/png');

        const canvas_tabla_ina_alumno_general = await html2canvas(elemento_tabla_ina_alumno_general, { scale: 1.2 });
        imagen_tabla_ina_alumno_general = canvas_tabla_ina_alumno_general.toDataURL('image/png');

        const canvas_tabla_low_curso = await html2canvas(elemento_tabla_low_curso, { scale: 1.2 });
        imagen_tabla_low_curso = canvas_tabla_low_curso.toDataURL('image/png');

        // Obtener el ancho y alto de la imagen
        ancho_imagen_tabla_ina_alumno_general = canvas_tabla_ina_alumno_general.width;
        alto_imagen_tabla_ina_alumno_general = canvas_tabla_ina_alumno_general.height;

        ancho_imagen_tabla_low_curso = canvas_tabla_low_curso.width;
        alto_imagen_tabla_low_curso = canvas_tabla_low_curso.height;

        revertDimensions(elemento_grafica_colegio);
    };

    const addImagesToPDF = () => {
        // hoja #1
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }

        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Contenido del informe", 10, 35);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 45, intro_informe, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_grafica_colegio, 'PNG', 10, 110, 230, 105);

        // hoja #2
        var existe_analsis_cualitativo = reporte_informe_general_IA.analsis_cualitativo;
        console.log(existe_analsis_cualitativo.length);
        if (existe_analsis_cualitativo.length != 0) {
            pdf.addPage();
            if (/base64/.test(logo_colegio)) {
                pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
            }
            pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor("#9a0707");
            pdf.text("Análisis Cualitativo de Datos", 10, 35);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            pdf.setTextColor("#000");
            pdf.text(10, 45, reporte_informe_general_IA.analsis_cualitativo, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })

        }
        // hoja #3
        const resize_imagen_tabla_ina_alumno_general = redimensionarImagen(ancho_imagen_tabla_ina_alumno_general, alto_imagen_tabla_ina_alumno_general, 100);
        pdf.addPage();
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Ranking de Inasistencia de Alumnos", 10, 35);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 45, intro_ina_informe, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_ina_alumno_general, 'PNG', 55, 90, resize_imagen_tabla_ina_alumno_general.width, resize_imagen_tabla_ina_alumno_general.height);

        // hoja #4
        const resize_imagen_tabla_low_curso = redimensionarImagen(ancho_imagen_tabla_low_curso, alto_imagen_tabla_low_curso, 80);
        pdf.addPage();
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Promedio de asistencias de cursos por mes", 10, 35);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 45, intro_promedio_cursos, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_low_curso, 'PNG', 65, 80, resize_imagen_tabla_low_curso.width, resize_imagen_tabla_low_curso.height);

        // hoja #5 conclusion
        var existe_conclusion_recomendacion = reporte_informe_general_IA.conclusion_recomendacion;
        console.log(existe_conclusion_recomendacion.length);
        if (existe_conclusion_recomendacion.length != 0) {

            pdf.addPage();
            if (/base64/.test(logo_colegio)) {
                pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
            }
            pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor("#9a0707");
            pdf.text("Conclusiones y Recomendaciones", 10, 35);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            pdf.setTextColor("#000");
            pdf.text(10, 45, reporte_informe_general_IA.conclusion_recomendacion, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })

        }

        const base64String = pdf.output('dataurlstring');
        return base64String;
    };

    await captureImages();
    return addImagesToPDF();
};

export const generateInformeCursoPDF = async (nombreColegio, mes, anio, curso, info_curso, logo_colegio) => {
    const elemento_grafica_curso = document.getElementById('grafica-curso-mes-informe');
    const elemento_tabla_alumno_curso = document.getElementById('tabla-full-alumnos-curso-informe');

    var intro_informe = template_intro_informe_curso_mes(mes, anio, curso, info_curso.asistencia);
    var intro_informe_alumno_curso = template_intro_informe_tabla_alumnos_curso_mes();

    const pdf = new jsPDF();
    pdf.setPage('A1');

    let imagen_grafica_curso;
    let imagen_tabla_alumnos_curso;
    let ancho_imagen_grafica_curso, alto_imagen_grafica_curso;
    let ancho_imagen_tabla_alumnos_curso, alto_imagen_tabla_alumnos_curso;

    const captureImages = async () => {

        if (window.innerWidth <= 2046) {
            setFixedDimensions(elemento_grafica_curso, 2046, 800);
        }

        console.log("window.innerWidth : " + window.innerWidth)

        await delay(1000);

        const canvas_grafica_curso = await html2canvas(elemento_grafica_curso, { scale: 1.2 });
        imagen_grafica_curso = canvas_grafica_curso.toDataURL('image/png');

        const canvas_tabla_alumnos_curso = await html2canvas(elemento_tabla_alumno_curso, { scale: 1.2 });
        imagen_tabla_alumnos_curso = canvas_tabla_alumnos_curso.toDataURL('image/png');

        ancho_imagen_tabla_alumnos_curso = canvas_tabla_alumnos_curso.width;
        alto_imagen_tabla_alumnos_curso = canvas_tabla_alumnos_curso.height;

        ancho_imagen_grafica_curso = canvas_grafica_curso.width;
        alto_imagen_grafica_curso = canvas_grafica_curso.height;

        revertDimensions(elemento_grafica_curso);
    };

    const addImagesToPDF = () => {
        // hoja #1
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }

        const resize_imagen_grafica_curso = redimensionarImagen(ancho_imagen_grafica_curso, alto_imagen_grafica_curso, 240);
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Informe de Asistencia por Curso", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 40, intro_informe, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_grafica_curso, 'PNG', 10, 110, resize_imagen_grafica_curso.width, resize_imagen_grafica_curso.height);



        // hoja #2
        const resize_imagen_tabla_alumnos_curso = redimensionarImagen(ancho_imagen_tabla_alumnos_curso, alto_imagen_tabla_alumnos_curso, 55);
        pdf.addPage();
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Tabla de asistencia de alumnos", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 40, intro_informe_alumno_curso, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_alumnos_curso, 'PNG', 65, 65, resize_imagen_tabla_alumnos_curso.width, resize_imagen_tabla_alumnos_curso.height);
        var outputFile = nombreColegio.replace(/\s+/g, "-");
        pdf.save(`informe-curso-${capitalize(outputFile)}-${mes}-${anio}.pdf`);
    };

    await captureImages();
    addImagesToPDF();

};


export const generateInformeCursoPDFEmail = async (mes, anio, curso, info_curso, logo_colegio) => {

    const elemento_grafica_curso = document.getElementById('grafica-curso-mes-informe');
    const elemento_tabla_alumno_curso = document.getElementById('tabla-full-alumnos-curso-informe');

    var intro_informe = template_intro_informe_curso_mes(mes, anio, curso, info_curso.asistencia);
    var intro_informe_alumno_curso = template_intro_informe_tabla_alumnos_curso_mes();

    const pdf = new jsPDF();
    pdf.setPage('A1');

    let imagen_grafica_curso;
    let imagen_tabla_alumnos_curso;
    let ancho_imagen_grafica_curso, alto_imagen_grafica_curso;
    let ancho_imagen_tabla_alumnos_curso, alto_imagen_tabla_alumnos_curso;

    const captureImages = async () => {

        if (window.innerWidth <= 2046) {
            setFixedDimensions(elemento_grafica_curso, 2046, 800);
        }

        console.log("window.innerWidth : " + window.innerWidth)

        await delay(1000);

        const canvas_grafica_curso = await html2canvas(elemento_grafica_curso, { scale: 1.2 });
        imagen_grafica_curso = canvas_grafica_curso.toDataURL('image/png');

        const canvas_tabla_alumnos_curso = await html2canvas(elemento_tabla_alumno_curso, { scale: 1.2 });
        imagen_tabla_alumnos_curso = canvas_tabla_alumnos_curso.toDataURL('image/png');

        ancho_imagen_tabla_alumnos_curso = canvas_tabla_alumnos_curso.width;
        alto_imagen_tabla_alumnos_curso = canvas_tabla_alumnos_curso.height;

        ancho_imagen_grafica_curso = canvas_grafica_curso.width;
        alto_imagen_grafica_curso = canvas_grafica_curso.height;

        revertDimensions(elemento_grafica_curso);
    };

    const addImagesToPDF = () => {
        // hoja #1
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 10, 15);
        }

        const resize_imagen_grafica_curso = redimensionarImagen(ancho_imagen_grafica_curso, alto_imagen_grafica_curso, 240);
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Contenido del informe", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 40, intro_informe, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_grafica_curso, 'PNG', 10, 110, resize_imagen_grafica_curso.width, resize_imagen_grafica_curso.height);



        // hoja #2
        const resize_imagen_tabla_alumnos_curso = redimensionarImagen(ancho_imagen_tabla_alumnos_curso, alto_imagen_tabla_alumnos_curso, 55);
        pdf.addPage();
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
        }
        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Tabla de asistencia de alumnos", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 40, intro_informe_alumno_curso, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_alumnos_curso, 'PNG', 65, 65, resize_imagen_tabla_alumnos_curso.width, resize_imagen_tabla_alumnos_curso.height);

        const base64String = pdf.output('dataurlstring');
        return base64String;
    };

    await captureImages();
    return addImagesToPDF();
};

export const generateInformeAsistenciaAlumnoPDF = async (nombreColegio, mes, anio, estado_mapa, logo_colegio) => {
    const elemento_calendario_asistencia_alumno = document.getElementById('calendario-asistencia-alumno-curso-informe');
    const elemento_tabla_informacion_alumno = document.getElementById('tabla-informacion-alumno');
    const elemento_curva_asistencia_alumno = document.getElementById('curva-asistencia-alumno-curso-mes-informe');
    const elemento_mapa_asistencia_alumno = document.getElementById('mapa-asistencia-alumno-curso-mes-informe');

    var intro_datos_alumno_curso = template_intro_informe_datos_alumno_curso_mes();
    var datos_alumno_curva_asistencia = template_curva_asistencia_informe_datos_alumno_curso_mes();
    var datos_alumno_localizacion_asistencia = template_localizacion_asistencia_informe_datos_alumno_curso_mes();

    const pdf = new jsPDF();
    pdf.setPage('A1');

    let imagen_calendario_asistencia_alumno;
    let imagen_tabla_informacion_alumno;
    let imagen_curva_asistencia_alumno;
    let imagen_mapa_asistencia_alumno;

    const captureImages = async () => {

        if (window.innerWidth <= 2046) {
            setFixedDimensions(elemento_curva_asistencia_alumno, 2046, 800);
        }

        await delay(1000);

        const canvas_curva_asistencia_alumno = await html2canvas(elemento_curva_asistencia_alumno, { scale: 1.2 });
        imagen_curva_asistencia_alumno = canvas_curva_asistencia_alumno.toDataURL('image/png');

        revertDimensions(elemento_curva_asistencia_alumno);

        /* await new Promise((resolve) => {
            const checkMapLoaded = setInterval(() => {
                const mapCanvas = elemento_mapa_asistencia_alumno.querySelector('canvas');
                console.log(mapCanvas)
                if (mapCanvas) {
                    clearInterval(checkMapLoaded);
                    resolve();
                }
            }, 100);
        }); */

        const canvas_calendario_asistencia_alumno = await html2canvas(elemento_calendario_asistencia_alumno, { scale: 1.2 });
        imagen_calendario_asistencia_alumno = canvas_calendario_asistencia_alumno.toDataURL('image/png');
        const canvas_imagen_tabla_informacion_alumno = await html2canvas(elemento_tabla_informacion_alumno, { scale: 1.2 });
        imagen_tabla_informacion_alumno = canvas_imagen_tabla_informacion_alumno.toDataURL('image/png');

        //const canvas_mapa_asistencia_alumno = await html2canvas(elemento_mapa_curva_asistencia_alumno, { scale: 1.2 });
        //imagen_mapa_asistencia_alumno = canvas_mapa_asistencia_alumno.toDataURL('image/png');

        let canvas_mapa_asistencia_alumno;

        if (estado_mapa) {
            const mapNode = elemento_mapa_asistencia_alumno.querySelector('.gm-style>div');
            //const mapNode = mapContainer.querySelector('.gm-style>div>div>div:last-child>div');
            /* 
            const transformMatrix = getComputedStyle(mapNode).transform.split(',');
            mapNode.setAttribute(
                'style',
                'left:' +
                parseFloat(transformMatrix[4]) +
                'px;top:' +
                parseFloat(transformMatrix[5]) +
                'px;transform: none;position: absolute;z-index: 983;'
            ); */

            if (mapNode) {
                canvas_mapa_asistencia_alumno = await html2canvas(mapNode, { useCORS: true });
                imagen_mapa_asistencia_alumno = canvas_mapa_asistencia_alumno.toDataURL('image/png');
            } else {
                console.error('No se encontró el nodo del mapa');
            }
        }


    };

    const addImagesToPDF = () => {
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 10, 15);
        }

        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Datos Personales y Calendarización de la Asistencia", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");

        pdf.text(10, 40, intro_datos_alumno_curso, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_informacion_alumno, 'PNG', 10, 65, 80, 60);
        pdf.addImage(imagen_calendario_asistencia_alumno, 'PNG', 102, 65, 100, 60);

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Gráfico con Curva de Asistencia General", 10, 140);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 150, datos_alumno_curva_asistencia, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_curva_asistencia_alumno, 'PNG', 40, 180, 170, 80);

        if (estado_mapa) {


            // hoja #2
            pdf.addPage();
            if (/base64/.test(logo_colegio)) {
                pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
            }
            pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor("#9a0707");
            pdf.text("Ubicación geográfica del Estudiante", 10, 30);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            pdf.setTextColor("#000");
            pdf.text(10, 40, datos_alumno_localizacion_asistencia, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
            pdf.addImage(imagen_mapa_asistencia_alumno, 'PNG', 50, 70, 120, 80);
        }

        var outputFile = nombreColegio.replace(/\s+/g, "-");
        pdf.save(`informe-asistencia-alumno-${capitalize(outputFile)}-${mes}-${anio}.pdf`);
    };

    await captureImages();
    addImagesToPDF();

};

export const generateInformeAsistenciaAlumnoPDFEmail = async (nombreColegio, estado_mapa, logo_colegio) => {
    const elemento_calendario_asistencia_alumno = document.getElementById('calendario-asistencia-alumno-curso-informe');
    const elemento_tabla_informacion_alumno = document.getElementById('tabla-informacion-alumno');
    const elemento_curva_asistencia_alumno = document.getElementById('curva-asistencia-alumno-curso-mes-informe');
    const elemento_mapa_asistencia_alumno = document.getElementById('mapa-asistencia-alumno-curso-mes-informe');

    var intro_datos_alumno_curso = template_intro_informe_datos_alumno_curso_mes();
    var datos_alumno_curva_asistencia = template_curva_asistencia_informe_datos_alumno_curso_mes();
    var datos_alumno_localizacion_asistencia = template_localizacion_asistencia_informe_datos_alumno_curso_mes();

    const pdf = new jsPDF();
    pdf.setPage('A1');

    let imagen_calendario_asistencia_alumno;
    let imagen_tabla_informacion_alumno;
    let imagen_curva_asistencia_alumno;
    let imagen_mapa_asistencia_alumno;

    const captureImages = async () => {

        if (window.innerWidth <= 2046) {
            setFixedDimensions(elemento_curva_asistencia_alumno, 2046, 800);
        }

        await delay(1000);

        const canvas_curva_asistencia_alumno = await html2canvas(elemento_curva_asistencia_alumno, { scale: 1.2 });
        imagen_curva_asistencia_alumno = canvas_curva_asistencia_alumno.toDataURL('image/png');

        revertDimensions(elemento_curva_asistencia_alumno);

        /* await new Promise((resolve) => {
            const checkMapLoaded = setInterval(() => {
                const mapCanvas = elemento_mapa_asistencia_alumno.querySelector('canvas');
                console.log(mapCanvas)
                if (mapCanvas) {
                    clearInterval(checkMapLoaded);
                    resolve();
                }
            }, 100);
        }); */

        const canvas_calendario_asistencia_alumno = await html2canvas(elemento_calendario_asistencia_alumno, { scale: 1.2 });
        imagen_calendario_asistencia_alumno = canvas_calendario_asistencia_alumno.toDataURL('image/png');
        const canvas_imagen_tabla_informacion_alumno = await html2canvas(elemento_tabla_informacion_alumno, { scale: 1.2 });
        imagen_tabla_informacion_alumno = canvas_imagen_tabla_informacion_alumno.toDataURL('image/png');

        //const canvas_mapa_asistencia_alumno = await html2canvas(elemento_mapa_curva_asistencia_alumno, { scale: 1.2 });
        //imagen_mapa_asistencia_alumno = canvas_mapa_asistencia_alumno.toDataURL('image/png');

        let canvas_mapa_asistencia_alumno;

        if (estado_mapa) {
            const mapNode = elemento_mapa_asistencia_alumno.querySelector('.gm-style>div');
            //const mapNode = mapContainer.querySelector('.gm-style>div>div>div:last-child>div');
            /* 
            const transformMatrix = getComputedStyle(mapNode).transform.split(',');
            mapNode.setAttribute(
                'style',
                'left:' +
                parseFloat(transformMatrix[4]) +
                'px;top:' +
                parseFloat(transformMatrix[5]) +
                'px;transform: none;position: absolute;z-index: 983;'
            ); */

            if (mapNode) {
                canvas_mapa_asistencia_alumno = await html2canvas(mapNode, { useCORS: true });
                imagen_mapa_asistencia_alumno = canvas_mapa_asistencia_alumno.toDataURL('image/png');
            } else {
                console.error('No se encontró el nodo del mapa');
            }
        }


    };

    const addImagesToPDF = () => {
        if (/base64/.test(logo_colegio)) {
            pdf.addImage(logo_colegio, 'JPEG', 15, 6, 10, 15);
        }

        pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Datos Personales y Calendarización de la Asistencia", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");

        pdf.text(10, 40, intro_datos_alumno_curso, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_tabla_informacion_alumno, 'PNG', 10, 65, 80, 60);
        pdf.addImage(imagen_calendario_asistencia_alumno, 'PNG', 102, 65, 100, 60);

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(16);
        pdf.setTextColor("#9a0707");
        pdf.text("Gráfico con Curva de Asistencia General", 10, 140);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.setTextColor("#000");
        pdf.text(10, 150, datos_alumno_curva_asistencia, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
        pdf.addImage(imagen_curva_asistencia_alumno, 'PNG', 40, 180, 170, 80);

        if (estado_mapa) {


            // hoja #2
            pdf.addPage();
            if (/base64/.test(logo_colegio)) {
                pdf.addImage(logo_colegio, 'JPEG', 15, 6, 15, 0);
            }
            pdf.addImage(logoIlumenData, 'PNG', 160, 6, 40, 20);
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor("#9a0707");
            pdf.text("Ubicación geográfica del Estudiante", 10, 30);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            pdf.setTextColor("#000");
            pdf.text(10, 40, datos_alumno_localizacion_asistencia, { maxWidth: 185, align: "justify", lineHeightFactor: 1.5 })
            pdf.addImage(imagen_mapa_asistencia_alumno, 'PNG', 50, 70, 120, 80);
        }

        const base64String = pdf.output('dataurlstring');
        return base64String;
    };

    await captureImages();
    return addImagesToPDF();

};