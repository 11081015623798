import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { capitalize } from "../Utils/utils";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = import.meta.env.VITE_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = import.meta.env.VITE_API_EDUTECH_HOST;
const API_EDUTECH_PORT = import.meta.env.VITE_API_EDUTECH_PORT;

export const IlumenDataContext = createContext();

/*
0 = no trabajado
1 = presente
2 = ausente
3 = retirado
*/

export const IlumenDataProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [seleccionColegio, setSeleccionColegio] = useState(null);
    const [selectNumeroAnio, setSelectNumeroAnio] = useState(null);
    const [selectNumeroMes, setSelectNumeroMes] = useState(null);
    const [selectIdCurso, setSelectIdCurso] = useState(null);

    const [infoGeneral, setInfoGeneral] = useState(null);
    const [listNumeroMeses, setListNumeroMeses] = useState(null);

    const [inasistenciaAlumnoMes, setInasistenciaAlumnoMes] = useState([]);
    const [labelCursosAsistenciaTotalColegio, setLabelCursosAsistenciaTotalColegio] = useState([]);
    const [labelCursosAsistenciaXcurso, setLabelCursosAsistenciaXcurso] = useState([]);
    const [labelAsistenciaAlumnoXcursoMes, setLabelAsistenciaAlumnoXcursoMes] = useState([]);
    const [infoAlumnoAsistenciaXcursoMes, setInfoAlumnoAsistenciaXcursoMes] = useState([]);
    const [infoProfesorJefe, setInfoProfesorJefe] = useState([]);
    const [correoProfesorJefe, setCorreoProfesorJefe] = useState("");
    

    const [viewGraficaCurso, setViewGraficaCurso] = useState(false);
    const [viewGraficaAlumno, setViewGraficaAlumno] = useState(false);
    const [enableButtonPdfEmail, setEnableButtonPdfEmail] = useState(true);

    const [directivosSeleccionadosNombre, setDirectivosSeleccionadosNombre] = useState([]);
    const [cursosSeleccionadosEmail, setCursosSeleccionadosEmail] = useState([]);
    const [profesorJefeSeleccionadosEmail, setProfesorJefeSeleccionadosEmail] = useState([]);
    const [directivos, setDirectivos] = useState([]);
    const [progress, setProgress] = useState(1);

    const [base64InformesCursosPDF, setBase64InformesCursosPDF] = useState([]);

    const [nombreColegio, setNombreColegio] = useState('');
    const [logoColegio, setLogoColegio] = useState('');

    const [alumnoSeleccionadoIdNomina, setAlumnoSeleccionadoIdNomina] = useState(null);

    const [isOpenAcordionRanking, setIsOpenAcordionRanking] = useState(false);

    const [diasHabiles, setDiasHabiles] = useState([]);
    


    const getMesesAsistencia = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_meses_asistencia`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setInfoGeneral(response.data.colegioCurso);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAsistenciaXMesAnio = async (mes, anio, id_colegio) => {
        try {
            const form = { mes, anio, id_colegio }
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_asistencia_mes_anio`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                setLabelCursosAsistenciaTotalColegio(response.data.cursosPromedio);
                setInasistenciaAlumnoMes(response.data.asistenciaAlumnoAusente);
                setIsLoading(false);
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAsistenciaAlumnoXcurso = async (mes, anio, id_colegio, id_curso) => {
        try {
            const form = { mes, anio, id_colegio, id_curso }
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_asistencia_curso`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                setLabelCursosAsistenciaXcurso(response.data.asistenciaXcurso);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAsistenciaAlumnoXmes = async (mes, anio, id_colegio, id_curso, id_nomina_alumno) => {
        try {
            const form = { mes, anio, id_colegio, id_curso, id_nomina_alumno }
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_asistencia_alumno`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                setLabelAsistenciaAlumnoXcursoMes(response.data.asistenciaAlumnoXcursoMes);
                setInfoAlumnoAsistenciaXcursoMes(response.data.infoAlumno);
                setDiasHabiles(response.data.diasHabiles);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const enviarAsistenciaInforme = async (emails, informePdf, anio, mes, nombre_colegio) => {
        try {
            const form = { id_colegio:seleccionColegio, emails, informePdf, anio, mes, nombre_colegio }

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/send_informe_colegio`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const enviarAsistenciaInformeCurso = async (emails, informePdf, anio, mes, nombre_colegio) => {
        try {
            const form = { id_colegio:seleccionColegio, emails, informePdf, anio, mes, nombre_colegio }

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/send_informe_curso`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    
    const enviarAsistenciaInformeAsistenciaAlumno = async (emails, informePdf, anio, mes, nombre_colegio, id_curso, id_nomina_alumno) => {
        try {
            //console.log(infoAlumnoAsistenciaXcursoMes)
            var nombre_alumno = capitalize(infoAlumnoAsistenciaXcursoMes.nombres + " " + infoAlumnoAsistenciaXcursoMes.ape_paterno);
            const form = { id_colegio:seleccionColegio, nombre_alumno: nombre_alumno, emails, informePdf, anio, mes, nombre_colegio, id_curso, id_nomina_alumno }

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/send_informe_asistencia_alumno`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getDirectivosDocentes = async (id_colegio) => {
        try {
            const form = { id_colegio };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_directivos_docentes`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                setDirectivos(response.data.directivos);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getProfesorJefe = async ( id_colegio, anio, id_curso) => {
        try {
            const form = { id_colegio, anio, id_curso };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_profesor_jefe`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                //setDirectivos(response.data.directivos);
                setInfoProfesorJefe(response.data.profesorJefe);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const ilumenDataContextValue = {
        isLoading,
        setIsLoading,
        seleccionColegio,
        setSeleccionColegio,
        getMesesAsistencia,
        infoGeneral,
        setInfoGeneral,
        listNumeroMeses,
        setListNumeroMeses,
        selectNumeroAnio,
        setSelectNumeroAnio,
        selectNumeroMes,
        setSelectNumeroMes,
        getAsistenciaXMesAnio,
        labelCursosAsistenciaTotalColegio,
        setLabelCursosAsistenciaTotalColegio,
        getAsistenciaAlumnoXcurso,
        labelCursosAsistenciaXcurso,
        setLabelCursosAsistenciaXcurso,
        viewGraficaCurso,
        setViewGraficaCurso,
        selectIdCurso,
        setSelectIdCurso,
        getAsistenciaAlumnoXmes,
        labelAsistenciaAlumnoXcursoMes,
        setLabelAsistenciaAlumnoXcursoMes,
        viewGraficaAlumno,
        setViewGraficaAlumno,
        infoAlumnoAsistenciaXcursoMes,
        setInfoAlumnoAsistenciaXcursoMes,
        enviarAsistenciaInforme,
        enviarAsistenciaInformeCurso,
        enableButtonPdfEmail,
        setEnableButtonPdfEmail,
        directivosSeleccionadosNombre,
        setDirectivosSeleccionadosNombre,
        cursosSeleccionadosEmail,
        setCursosSeleccionadosEmail,
        profesorJefeSeleccionadosEmail,
        setProfesorJefeSeleccionadosEmail,
        nombreColegio, 
        setNombreColegio,
        getDirectivosDocentes,
        directivos,
        progress,
        setProgress,
        base64InformesCursosPDF,
        setBase64InformesCursosPDF,
        infoProfesorJefe,
        getProfesorJefe,
        correoProfesorJefe,
        setCorreoProfesorJefe,
        enviarAsistenciaInformeAsistenciaAlumno,
        alumnoSeleccionadoIdNomina,
        setAlumnoSeleccionadoIdNomina,
        isOpenAcordionRanking,
        setIsOpenAcordionRanking,
        inasistenciaAlumnoMes,
        setInasistenciaAlumnoMes,
        diasHabiles,
        setDiasHabiles,
        logoColegio,
        setLogoColegio,
    };

    return (
        <IlumenDataContext.Provider value={ilumenDataContextValue}>
            {children}
        </IlumenDataContext.Provider>
    );
};
