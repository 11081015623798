import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../../contexts/IlumenDataContext";
import { IconButton } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { DataGrid } from "@mui/x-data-grid";

export default function TablaAlumnosCursoInformeFull({ info, orden }) {

    //console.log(info)
    const fontSize = {
        xl: "12px",
        lg: "12px",
        md: "12px",
        sm: "12px",
        xs: "11px",
    }

    function capitalize(frase) {
        return frase
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }


    const tablaAsistenciaCurso = [];

    const columns = [
        { field: 'id', headerName: 'ID', width: 30 },
        {
            field: 'asistencia',
            headerName: 'Asistencia',
            width: 80,
            editable: false,
            renderCell: (params) => (
                <>
                    {params.row.asistencia}%
                </>
            ),
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            width: 110,
            editable: false,
            renderCell: (params) => (
                <>
                    {capitalize(params.row.nombre)}
                </>
            ),
        },
        {
            field: 'ape_paterno',
            headerName: 'Apellido Paterno',
            width: 110,
            editable: false,
            renderCell: (params) => (
                <>
                    {capitalize(params.row.ape_paterno)}
                </>
            ),
        },
        {
            field: 'ape_materno',
            headerName: 'Apellido Materno',
            width: 110,
            editable: false,
            renderCell: (params) => (
                <>
                    {capitalize(params.row.ape_materno)}
                </>
            ),
        },
    ];

    const rows = [];

    if (Object.keys(info).length !== 0) {

        info.alumnos.forEach((item) => {

            let objAsistenciaAlumnosCurso = {
                id: `asistencia-alumno-curso-${item.id_nomina_alumno}`,
                id_nomina_alumno: item.id_nomina_alumno,
                nombre: item.nombres,
                ape_paterno: item.ape_paterno,
                ape_materno: item.ape_materno,
                asistencia: item.asistencia_mes,
            };

            rows.push(objAsistenciaAlumnosCurso);

        });
    }
    const hiddenColumns = ['id'];

    if (orden === "top") {
        rows.sort((a, b) => b.asistencia - a.asistencia);
    }

    if (orden === "low") {
        rows.sort((a, b) => a.asistencia - b.asistencia);
    }

    tablaAsistenciaCurso.push(
        <React.Fragment key="tabla-asistencia-alumno-curso-full">
            <DataGrid
                autoHeight
                autoWidth
                rows={rows}
                columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
                pageSize={rows.length} // Ajusta el tamaño de página al número total de filas
                rowsPerPageOptions={[rows.length]} // Opcional: ajusta las opciones de filas por página a la longitud total de las filas
                disableRowSelectionOnClick
                rowHeight={30}
                columnHeaderHeight={40}
                sx={{ fontSize: fontSize }}
                hideFooter={true}
            />

        </React.Fragment>
    );
    return tablaAsistenciaCurso;
}