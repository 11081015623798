import React, { useState, useContext, useEffect, useRef } from "react";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import SliderStepsMeses from './SliderStepsMeses';
import SliderStepsAnios from './SliderStepsAnios';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, FormControl, Grid, IconButton, InputLabel, NativeSelect, TextField, Typography, Hidden, Stack, Rating, Divider, Card, CardContent } from '@mui/material';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";
import { GoogleMapsContext } from "../../contexts/GoogleMapsContext";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import TablaTopCurso from "./TablaInfoAlumno/ReporteMensual/TablaTopCurso";
import TablaLowCurso from "./TablaInfoAlumno/ReporteMensual/TablaLowCurso";
import TablaAlumnosCurso from "./TablaAlumnosCurso";
import logoIlumenData from "../../assets/logo-ilumen-pdf.png";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import EmailIcon from '@mui/icons-material/Email';

import './loading.css';

import { getNombreColegio, getNombreCurso, delay, capitalize, fontSize, fontSizeTable, fontSizeDialog, fontSizeTitle } from "../../Utils/utils";
import {
    generateInformeColegioPDF, generateInformeCursoPDF,
    generateInformeColegioPDFEmail, generateInformeCursoPDFEmail,
    generateInformeAsistenciaAlumnoPDF, generateInformeAsistenciaAlumnoPDFEmail
} from "../../Utils/generateInformePDF";

import GraphCurso from "./Grafica/GraphCurso";
import TablaAlumnosCursoInformeFull from "./Informe/TablaAlumnosCursoInformeFull";


import TablaTopCursoInforme from "./Informe/TablaTopCursoInforme";
import TablaLowCursoInforme from "./Informe/TablaLowCursoInforme";
import DialogInformeColegio from "./DialogInformeColegio";

import { ThemeProvider } from '@mui/material/styles';
import { ThemeDialog } from './Informe/ThemeDialog';
import DialogInformeCurso from "./DialogInformeCurso";
import Loading from "../Loading";
import { forEach } from "lodash";

import { deflate, inflate } from 'pako';
import { Buffer } from 'buffer';
import CalendarioMesAlumno from "./Calendario/CalendarioMesAlumno";
import DialogInformeAsistenciaAlumno from "./DialogInformeAsistenciaAlumno";
import LegendBoxes from "./LegendBoxes";
import TablaAsistenciaAlumnoDiasMesInforme from "./Informe/TablaAsistenciaAlumnoDiasMesInforme";
import TablaAlumnosCursoInAsistencia from "./TablaAlumnosCursoInAsistencia";
import RatingFaces from "./Informe/RatingFaces";
import HorizontalAccordion from "../AcordeonHorizontal/HorizontalAccordion";
import TablaInfoAlumno from "./TablaInfoAlumno/ReporteMensual/TablaInfoAlumno";
import GoogleMapsInfoAlumno from "../GoogleMaps/GoogleMapsInfoAlumno";
import TablaInasistenciaAlumnosMes from "./TablaInfoAlumno/ReporteMensual/TablaInasistenciaAlumnosMes";
import ModalInformacionAlumno from "./Modal/ReporteMensual/ModalInformacionAlumno";
import CurvaAsistenciaAlumnoMes from "./Grafica/CurvaAsistenciaAlumnoMes";
import TablaInasistenciaAlumnosMesInforme from "./Informe/TablaInasistenciaAlumnosMesInforme";
import { convertToCSV, downloadCSV } from "../../Utils/csv";
import { InformeIAContext } from "../../contexts/InformeIAContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const API_KEY_GOOGLEMAPS = import.meta.env.VITE_GOOGLE_MAPS;

export default function AsistenciaTotalColegio() {

    const {
        isLoading,
        setIsLoading,
        seleccionColegio,
        setSeleccionColegio,
        getMesesAsistencia,
        infoGeneral,
        listNumeroMeses,
        setListNumeroMeses,
        selectNumeroAnio,
        setSelectNumeroAnio,
        selectNumeroMes,
        labelCursosAsistenciaTotalColegio,
        setLabelCursosAsistenciaTotalColegio,
        getAsistenciaAlumnoXcurso,
        labelCursosAsistenciaXcurso,
        viewGraficaCurso,
        setViewGraficaCurso,
        selectIdCurso,
        setSelectIdCurso,
        getAsistenciaAlumnoXmes,
        viewGraficaAlumno,
        setViewGraficaAlumno,
        labelAsistenciaAlumnoXcursoMes,
        infoAlumnoAsistenciaXcursoMes,
        enviarAsistenciaInforme,
        enviarAsistenciaInformeCurso,
        enableButtonPdfEmail,
        cursosSeleccionadosEmail,
        profesorJefeSeleccionadosEmail,
        nombreColegio,
        setNombreColegio,
        getDirectivosDocentes,
        directivos,
        directivosSeleccionadosNombre,
        setProgress,
        base64InformesCursosPDF,
        setBase64InformesCursosPDF,
        getProfesorJefe,
        correoProfesorJefe,
        setCorreoProfesorJefe,
        enviarAsistenciaInformeAsistenciaAlumno,
        alumnoSeleccionadoIdNomina,
        setAlumnoSeleccionadoIdNomina,
        isOpenAcordionRanking,
        setIsOpenAcordionRanking,
        inasistenciaAlumnoMes,
        diasHabiles,
        setLogoColegio,
        logoColegio,
    } = useContext(IlumenDataContext);

    const { setMarkersInfoAlumno, setMarkersColegioInfoAlumno } = useContext(GoogleMapsContext);
    const { getAnalisisCualitativoInformeGeneral } = useContext(InformeIAContext);

    const [openEnviarInforme, setOpenEnviarInforme] = useState(false);
    const [openEnviarInformeCurso, setOpenEnviarInformeCurso] = useState(false);
    const [openEnviarInformeAsistenciaAlumno, setOpenEnviarInformeAsistenciaAlumno] = useState(false);


    const [emailEnvioInformeColegio, setEmailEnvioInformeColegio] = useState('');
    const [emailEnvioInformeCurso, setEmailEnvioInformeCurso] = useState('');
    const [emailEnvioInformeAsistenciaAlumno, setEmailEnvioInformeAsistenciaAlumno] = useState('');

    const [mostrarFullTablaCurso, setMostrarFullTablaCurso] = useState(false);
    const [mostrarTablaTopCursos, setMostrarTablaTopCursos] = useState(false);
    const [viewMapAlumno, setViewMapAlumno] = useState(false);

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const lowAsistenciaAlumnosMesRef = React.useRef(null);
    const tablaTopCursosRef = React.useRef(null);
    const graficaCursoMesRef = React.useRef(null);
    const graficaCurvaAsistenciaMesRef = React.useRef(null);
    const mapaAsistenciaAlumnoMesRef = React.useRef(null);

    const [modalOpenInformacionAlumno, setModalOpenInformacionAlumno] = useState(false);

    const handleOpenModalInformacionAlumno = async (id_curso) => {
        setMarkersInfoAlumno([]);
        setMarkersColegioInfoAlumno([]);
        handleVerAsistenciaAlumno(id_curso)
        setModalOpenInformacionAlumno(true);

        await delay(500);
        scrollToRef(graficaCurvaAsistenciaMesRef);
    };

    const handleCloseModalInformacionAlumno = () => {
        setModalOpenInformacionAlumno(false);
    };

    const handleAccordionRankingClick = () => setIsOpenAcordionRanking(!isOpenAcordionRanking);

    const handleOpenEnviarInforme = () => {
        setOpenEnviarInforme(true);
    };

    const handleOpenEnviarInformeCurso = async () => {
        setOpenEnviarInformeCurso(true);
    };

    const handleOpenEnviarInformeAsistenciaAlumno = () => {

        setOpenEnviarInformeAsistenciaAlumno(true);
    };


    const handleCloseEnviarInforme = () => {
        setOpenEnviarInforme(false);
    };

    const handleCloseEnviarInformeCurso = () => {
        setOpenEnviarInformeCurso(false);
    };

    const handleCloseEnviarInformeAsistenciaAlumno = () => {
        setOpenEnviarInformeAsistenciaAlumno(false);
    };

    //console.log(isOpenAcordionRanking)

    function getEmailDirectivo(nombre_directivo) {
        var email = "";
        if (directivos.length !== 0) {
            for (const directivo of directivos) {
                //console.log(directivo.nombre)
                if (directivo.nombre === nombre_directivo) {
                    //console.log(directivo.email)
                    email = directivo.email;
                    break;
                }
            }
        }
        return email;
    }


    const [existeAnios, setExisteAnios] = useState(null);

    const gridSelectCursoBar = useRef(null);

    const nombreMes = ['',
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];


    const [barOptions, setBarOptions] = useState({
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.parsed.y + '%';
                    },
                },
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                color: '#000',
                font: {
                    size: "9px",
                    weight: 'bold',
                },
                formatter: (value, context) => {
                    return value + "%";
                }
            },
        },
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: { color: '#000000' },
            },
            x: {
                ticks: { color: 'black' }
            }
        }
    });



    const asistencias = labelCursosAsistenciaTotalColegio.map(item => item.asistencia);
    const ausentes = labelCursosAsistenciaTotalColegio.map(item => item.ausentes);

    if (seleccionColegio === null || !existeAnios) {
        const cursos = labelCursosAsistenciaTotalColegio.map(item => item.curso);
        var midata = {
            labels: cursos,
            datasets: [
                {
                    label: 'Asistencia',
                    data: [],
                    backgroundColor: "rgba(12,113,167,255)",
                    borderColor: "rgba(155,223,234)",
                    hoverBackgroundColor: "rgba(155,223,234,255)",
                    borderWidth: 1,
                },
                {
                    label: 'Ausentes',
                    data: [],
                    backgroundColor: "rgb(207, 63, 7, 1)",
                    borderColor: "rgb(255, 159, 64)",
                    hoverBackgroundColor: "rgb(246, 96, 38, 1)",
                    borderWidth: 1,
                }
            ]
        };

    } else {

        const cursos = labelCursosAsistenciaTotalColegio.map(item => item.curso);

        var midata = {
            labels: cursos,
            datasets: [
                {
                    label: 'Asistencia',
                    data: asistencias,
                    backgroundColor: "rgba(12,113,167,255)",
                    borderColor: "rgba(155,223,234)",
                    hoverBackgroundColor: "rgba(155,223,234,255)",
                    borderWidth: 1,
                },
                {
                    label: 'Ausentes',
                    data: ausentes,
                    backgroundColor: "rgb(207, 63, 7, 1)",
                    borderColor: "rgb(255, 159, 64)",
                    hoverBackgroundColor: "rgb(246, 96, 38, 1)",
                    borderWidth: 1,
                }
            ]
        };

    }

    if (viewGraficaCurso && labelCursosAsistenciaXcurso) {

        const promedioAlumnosXcurso = labelCursosAsistenciaXcurso.alumnos.map(item => item.asistencia_mes);
        const promedioAusenteAlumnosXcurso = labelCursosAsistenciaXcurso.alumnos.map(item => item.ausente_mes);
        const alumnosXcurso = labelCursosAsistenciaXcurso.alumnos.map(item => capitalize(item.nombres + " " + item.ape_paterno));

        var dataGraficaXcurso = {
            labels: alumnosXcurso,
            datasets: [
                {
                    label: 'Asistencia',
                    data: promedioAlumnosXcurso,
                    backgroundColor: "rgba(12,113,167,255)",
                    borderColor: "rgba(155,223,234)",
                    hoverBackgroundColor: "rgba(155,223,234,255)",
                    borderWidth: 1,
                },
                {
                    label: 'Ausentes',
                    data: promedioAusenteAlumnosXcurso,
                    backgroundColor: "rgb(207, 63, 7, 1)",
                    borderColor: "rgb(255, 159, 64)",
                    hoverBackgroundColor: "rgb(246, 96, 38, 1)",
                    borderWidth: 1,
                }
            ]
        };
    }

    if (viewGraficaAlumno && labelAsistenciaAlumnoXcursoMes) {

        const asistenciaXdiaAlumno = [];
        labelAsistenciaAlumnoXcursoMes.forEach(item => {
            if (item.estado === 1) {
                asistenciaXdiaAlumno.push(1);
            } else {
                asistenciaXdiaAlumno.push(0);
            }
        });

        const ausenteXdiaAlumno = [];
        labelAsistenciaAlumnoXcursoMes.forEach(item => {
            if (item.estado === 2) {
                ausenteXdiaAlumno.push(1);
            } else {
                ausenteXdiaAlumno.push(0);
            }
        });

        const diasDelMes = labelAsistenciaAlumnoXcursoMes.map(item => capitalize(item.dia_semana) + "(" + item.dia + ")");

        var dataGraficaAlumnoXCursoMes = {
            labels: diasDelMes,
            datasets: [
                {
                    label: 'Asistencia',
                    data: asistenciaXdiaAlumno,
                    backgroundColor: "rgba(12,113,167,255)",
                    borderColor: "rgba(155,223,234)",
                    hoverBackgroundColor: "rgba(155,223,234,255)",
                    borderWidth: 1,
                },
                {
                    label: 'Ausente',
                    data: ausenteXdiaAlumno,
                    backgroundColor: "rgb(207, 63, 7, 1)",
                    borderColor: "rgb(255, 159, 64)",
                    hoverBackgroundColor: "rgb(246, 96, 38, 1)",
                    borderWidth: 1,
                }
            ]
        };
    }

    /*     function getNombreColegio(id_colegio) {
            let nombre_colegio = "";
            if (Array.isArray(infoGeneral)) {
                infoGeneral.map((infoColegio) => {
                    if (infoColegio.colegio.id_colegio === id_colegio) {
                        nombre_colegio = infoColegio.colegio.colegio;
                    }
    
                });
            }
    
            return nombre_colegio;
        } */

    const renderOptionsColegios = () => {
        const options = [];

        options.push(
            <option key="colegio-null" value="">
            </option>
        );

        if (Array.isArray(infoGeneral)) {
            infoGeneral.map((infoColegio) => {
                //console.log(infoColegio.colegio.colegio)
                options.push(
                    <option key={"colegio-" + infoColegio.colegio.id_colegio} value={infoColegio.colegio.id_colegio}>
                        {capitalize(infoColegio.colegio.colegio)}
                    </option>
                );
            });
        }

        return options;
    };

    const handleSeleccionColegio = async (colegio) => {
        //console.log(listNumeroMeses);
        if (isNaN(parseInt(colegio))) {
            setSelectNumeroAnio(null);
            setSeleccionColegio(null);
            setListNumeroMeses(null);
            setExisteAnios(false);
            setLabelCursosAsistenciaTotalColegio([]);
        } else {
            setSeleccionColegio(parseInt(colegio));
            await getDirectivosDocentes(colegio);

            if (Array.isArray(infoGeneral)) {

                for (const infoColegio of infoGeneral) {

                    if (infoColegio.colegio.id_colegio === parseInt(colegio)) {
                        //console.log(infoColegio.colegio)
                        if (infoColegio.colegio.aniosAsistencia.length === 0) {
                            setSelectNumeroAnio(null);
                            setSeleccionColegio(null);
                            setListNumeroMeses(null);
                            setExisteAnios(false);
                            setLabelCursosAsistenciaTotalColegio([]);
                        } else {
                            setLabelCursosAsistenciaTotalColegio([]);
                            setSelectNumeroAnio(null);
                            setExisteAnios(true);
                            setListNumeroMeses(infoColegio.colegio.meses);
                            //console.log(infoColegio)
                            setLogoColegio(infoColegio.colegio.logo);
                        }

                        break;
                    }

                };

            }
        }

    }

    const handleVerCurso = async (id_curso) => {
        /*
        console.log("anio:" + selectNumeroAnio);
        console.log("numero mes: " + selectNumeroMes);
        console.log("id colegio:" + seleccionColegio);
        console.log("id curso: " + id_curso);*/
        setSelectIdCurso(id_curso);
        await getAsistenciaAlumnoXcurso(selectNumeroMes, selectNumeroAnio, seleccionColegio, id_curso);
        setViewGraficaCurso(true);




        setViewGraficaAlumno(false);
        await delay(500);
        scrollToRef(graficaCursoMesRef);
        /* 
                if (infoAlumnoAsistenciaXcursoMes.direccion) {
                    scrollToRef(mapaAsistenciaAlumnoMesRef);
                } else {
                    
                } */


    }

    const handleVerAsistenciaAlumno = async (id_nomina_alumno) => {
        /* console.log("anio:" + selectNumeroAnio);
        console.log("numero mes: " + selectNumeroMes);
        console.log("id colegio:" + seleccionColegio);
        console.log("id curso: " + selectIdCurso);
        console.log("id_nomina_alumno:" + parseInt(id_nomina_alumno)); */
        setAlumnoSeleccionadoIdNomina(parseInt(id_nomina_alumno));
        await getProfesorJefe(seleccionColegio, selectNumeroAnio, selectIdCurso);

        await getAsistenciaAlumnoXmes(selectNumeroMes, selectNumeroAnio, seleccionColegio, selectIdCurso, parseInt(id_nomina_alumno));
        setViewGraficaAlumno(true);
        setMarkersInfoAlumno([]);
        setMarkersColegioInfoAlumno([]);
    }


    useEffect(() => {

        getMesesAsistencia();

        const fetchNombreColegio = async () => {
            const name_colegio = await getNombreColegio(infoGeneral, seleccionColegio);
            setNombreColegio(name_colegio); // Actualiza el estado con el nombre del colegio
        };

        fetchNombreColegio(); // Llama a la función asíncrona

        if (window.innerWidth <= 600) {

            toast.warn(
                <>
                    Es recomendable girar la pantalla en posición horizontal para mejorar la visualización <ScreenRotationIcon fontSize="inherit" />
                </>, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                closeButton: false,
            });
        }

        const handleResize = () => {

            if (window.innerWidth <= 600) {

                toast.warn(
                    <>
                        Es recomendable girar la pantalla en posición horizontal para mejorar la visualización <ScreenRotationIcon fontSize="inherit" />
                    </>, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                    closeButton: false,
                });
            }

        };

        // Manejador de eventos para el cambio de tamaño de la ventana
        window.addEventListener('resize', handleResize);

        // Limpia el evento al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, [seleccionColegio]);




    const handlenEnviarInforme = async () => {
        setIsLoading(true);
        setMostrarTablaTopCursos(true);

        var directivosEmails = [];

        if (directivosSeleccionadosNombre.length !== 0) {
            directivosSeleccionadosNombre.map((directivo) => {
                directivosEmails.push(getEmailDirectivo(directivo));
            });
        }

        directivosEmails = directivosEmails.join(", ") + ", " + emailEnvioInformeColegio;
        var outputFile = await getNombreColegio(infoGeneral, seleccionColegio);

        const csv = convertToCSV(labelCursosAsistenciaTotalColegio);
        var reporte_informe_general_IA = await getAnalisisCualitativoInformeGeneral(csv);
        console.log(reporte_informe_general_IA)

        const informeColegioPDFbase64 = await generateInformeColegioPDFEmail(capitalize(outputFile), nombreMes[selectNumeroMes], selectNumeroAnio, logoColegio, reporte_informe_general_IA);
        const base64Data = informeColegioPDFbase64.split(';base64,').pop();
        //console.log(base64Data);

        // Comprime los datos
        const binaryString = Buffer.from(base64Data, 'base64');
        const data = new Uint8Array(binaryString);
        const compressed = deflate(data);
        const comprimido = Buffer.from(compressed).toString('base64');

        //console.log(comprimido);
        await enviarAsistenciaInforme(directivosEmails, comprimido, selectNumeroAnio, selectNumeroMes, nombreColegio);
        setMostrarTablaTopCursos(false);
        setIsLoading(false);
    };

    const handleEnviarInformeCursos = async () => {

        //setIsLoading(true);

        var outputFile = await getNombreColegio(infoGeneral, seleccionColegio);

        var directivosEmails = [];
        let nuevosInformes = [];

        if (directivosSeleccionadosNombre.length !== 0) {
            directivosSeleccionadosNombre.map((directivo) => {
                directivosEmails.push(getEmailDirectivo(directivo));
            });
        }

        directivosEmails = directivosEmails.join(", ") + ", " + emailEnvioInformeCurso;

        const regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
        const correosValidos = directivosEmails.match(regex);

        if (correosValidos === null) {
            return toast.error("Ingrese correo(s) o seleccione directivo a enviar informe.", {
                position: "bottom-left",
                autoClose: 1000,
            });
        }

        setMostrarFullTablaCurso(true);

        var total = cursosSeleccionadosEmail.length;
        setProgress(1);
        if (cursosSeleccionadosEmail.length !== 0) {
            for (const cursoSeleccionado of cursosSeleccionadosEmail) {
                var nuevoOutputFile = "";
                var nombre_curso = await getNombreCurso(labelCursosAsistenciaTotalColegio, cursoSeleccionado);
                var info_curso = labelCursosAsistenciaTotalColegio.filter(infocurso => infocurso.id_curso === cursoSeleccionado);
                info_curso = info_curso[0];
                //delay(5000)
                await handleVerCurso(cursoSeleccionado)
                await delay(1000)
                const informeColegioPDFbase64 = await generateInformeCursoPDFEmail(nombreMes[selectNumeroMes], selectNumeroAnio, nombre_curso, info_curso, logoColegio);
                const base64Data = informeColegioPDFbase64.split(';base64,').pop();
                //console.log(base64Data);

                // Comprime los datos
                const binaryString = Buffer.from(base64Data, 'base64');
                const data = new Uint8Array(binaryString);
                const compressed = deflate(data);
                const comprimido = Buffer.from(compressed).toString('base64');

                //console.log(comprimido);
                nuevoOutputFile = outputFile + " " + nombre_curso;
                nuevoOutputFile = nuevoOutputFile.replace(/\s+/g, "-");
                nuevosInformes.push({ pdf: comprimido, filename: nuevoOutputFile });
                setProgress((prevProgress) => (prevProgress < total ? prevProgress + 1 : prevProgress));
                await delay(1500)


            }

        } else {

            return toast.error("Seleccione el o los cursos a enviar reporte.", {
                position: "bottom-left",
                autoClose: 1000,
            });
        }
        //setBase64InformesCursosPDF(prevInformes => [...prevInformes, ...nuevosInformes]);
        //console.log(directivosEmails);
        await enviarAsistenciaInformeCurso(directivosEmails, nuevosInformes, selectNumeroAnio, selectNumeroMes, nombreColegio);
        setMostrarFullTablaCurso(false);

        setIsLoading(false);
    };

    const handlenEnviarInformeAsistenciaAlumno = async () => {
        //setIsLoading(true);
        //setMostrarTablaTopCursos(true);

        var directivosEmails = [];

        if (directivosSeleccionadosNombre.length !== 0) {
            directivosSeleccionadosNombre.map((directivo) => {
                directivosEmails.push(getEmailDirectivo(directivo));
            });
        }

        var estado_mapa = false;
        if (infoAlumnoAsistenciaXcursoMes.direccion) {
            estado_mapa = true;
        }

        var nombre_colegio = await getNombreColegio(infoGeneral, seleccionColegio);
        directivosEmails = directivosEmails.join(", ") + ", " + emailEnvioInformeColegio + ", " + correoProfesorJefe + ", " + emailEnvioInformeAsistenciaAlumno;

        const regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
        const correosValidos = directivosEmails.match(regex);

        if (correosValidos === null) {
            return toast.error("Ingrese correo(s) o seleccione directivo a enviar informe.", {
                position: "bottom-left",
                autoClose: 1000,
            });
        }

        var estado_mapa = false;
        if (infoAlumnoAsistenciaXcursoMes.direccion) {
            estado_mapa = true;
        }

        const informeAsistenciaAlumnoPDFbase64 = await generateInformeAsistenciaAlumnoPDFEmail(nombre_colegio, estado_mapa, logoColegio);

        const base64Data = informeAsistenciaAlumnoPDFbase64.split(';base64,').pop();

        // Comprime los datos
        const binaryString = Buffer.from(base64Data, 'base64');
        const data = new Uint8Array(binaryString);
        const compressed = deflate(data);
        const comprimido = Buffer.from(compressed).toString('base64');

        await enviarAsistenciaInformeAsistenciaAlumno(directivosEmails, comprimido, selectNumeroAnio, selectNumeroMes, nombreColegio, selectIdCurso, alumnoSeleccionadoIdNomina);
        //setMostrarTablaTopCursos(false);
        setIsLoading(false);
    };


    const handleEmailEnvioInformeColegioChange = (event) => {
        setEmailEnvioInformeColegio(event.target.value);
        //console.log(emailEnvioInformeColegio);
        //directivosSeleccionadosEmail
    };

    const handleEmailEnvioInformeCursoChange = (event) => {
        setEmailEnvioInformeCurso(event.target.value);
    };

    const handleEmailEnvioInformeAsistenciaAlumnoChange = (event) => {
        setEmailEnvioInformeAsistenciaAlumno(event.target.value);
    };

    const handleGenerateInformeColegioPDF = async () => {
        setIsLoading(true);
        var outputFile = await getNombreColegio(infoGeneral, seleccionColegio);
        // GENERA SEGMENTOS DE CONTENIDO DEL INFORME CON IA
        const csv = convertToCSV(labelCursosAsistenciaTotalColegio);
        var reporte_informe_general_IA = await getAnalisisCualitativoInformeGeneral(csv);
        console.log(reporte_informe_general_IA)

        setMostrarTablaTopCursos(true);
        await generateInformeColegioPDF(outputFile, nombreMes[selectNumeroMes], selectNumeroAnio, logoColegio, reporte_informe_general_IA);
        setMostrarTablaTopCursos(false);
        setIsLoading(false);
    };

    const handleGenerateInformeCursoPDF = async () => {
        setIsLoading(true);

        var outputFile = await getNombreColegio(infoGeneral, seleccionColegio);
        //console.log(cursosSeleccionadosEmail);
        setMostrarFullTablaCurso(true);
        //console.log(labelCursosAsistenciaTotalColegio);
        var total = cursosSeleccionadosEmail.length;
        setProgress(1);
        if (cursosSeleccionadosEmail.length !== 0) {
            for (const cursoSeleccionado of cursosSeleccionadosEmail) {
                var nombre_curso = await getNombreCurso(labelCursosAsistenciaTotalColegio, cursoSeleccionado);
                var info_curso = labelCursosAsistenciaTotalColegio.filter(infocurso => infocurso.id_curso === cursoSeleccionado);
                //console.log(info_curso)
                info_curso = info_curso[0];
                //delay(5000)
                await handleVerCurso(cursoSeleccionado)
                await delay(1000)
                await generateInformeCursoPDF(outputFile + " " + nombre_curso, nombreMes[selectNumeroMes], selectNumeroAnio, nombre_curso, info_curso, logoColegio);
                setProgress((prevProgress) => (prevProgress < total ? prevProgress + 1 : prevProgress));
                await delay(1500)


            }

        } else {

            toast.error("Seleccione el o los cursos a descargar.", {
                position: "bottom-left",
                autoClose: 1000,
            });
        }
        setMostrarFullTablaCurso(false);

        setIsLoading(false);

    };

    const handleGenerateInformeAsistenciaAlumnoPDF = async () => {
        setIsLoading(true);
        var outputFile = await getNombreColegio(infoGeneral, seleccionColegio);

        var estado_mapa = false;
        if (infoAlumnoAsistenciaXcursoMes.direccion) {
            estado_mapa = true;
        }

        await generateInformeAsistenciaAlumnoPDF(outputFile, nombreMes[selectNumeroMes], selectNumeroAnio, estado_mapa, logoColegio);

        setIsLoading(false);
    };


    const handleDownloadCSV = async () => {
        const csv = convertToCSV(labelCursosAsistenciaTotalColegio);
        downloadCSV(csv, 'asistencia_general_cursos.csv');
    };

    return (
        <>
            {isLoading && (
                <Loading />
            )}


            <Container maxWidth={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "80px" }} >
                <Grid container alignItems="flex-start" spacing={1}>
                    <Grid
                        key="grid-select-colegio-bar"
                        item
                        xl={10}
                        lg={10}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ m: "auto" }}
                    >
                        <Card>
                            <CardContent>
                                <Typography variant='h6' sx={{ fontSize: fontSize }}>
                                    {selectNumeroAnio !== null && selectNumeroMes !== null
                                        ? `${capitalize(nombreColegio)} Promedio de Asistencia Total del Colegio , Mes de ${nombreMes[selectNumeroMes]} ${selectNumeroAnio}`
                                        : `${capitalize(nombreColegio)} Promedio de Asistencia Total del Colegio`
                                    }
                                </Typography>
                                <div id="grafica-colegio-general-mes">
                                    <Bar data={midata} options={barOptions} plugins={[ChartDataLabels]} width={100} height={50} />
                                </div>
                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid
                        key="grid-sliders-colegio"
                        item
                        xl={2}
                        lg={2}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
                    >
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant='h6' sx={{ fontSize: fontSize }}>Establecimientos</Typography>
                                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                                    <InputLabel variant="standard" htmlFor="colegio">
                                        Colegio
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={0}
                                        size="small"
                                        inputProps={{
                                            name: "colegio",
                                            id: "colegio",
                                        }}
                                        onChange={(e) =>
                                            handleSeleccionColegio(e.target.value)
                                        }
                                    >
                                        {renderOptionsColegios()}
                                    </NativeSelect>
                                </FormControl>

                                {seleccionColegio && existeAnios && (
                                    <>
                                        <Grid container alignItems="flex-start" >
                                            <Grid
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                sx={{ m: "auto" }}
                                            >
                                                <Typography textAlign="start" sx={{ color: "#373737", fontSize: "12px", fontWeight: "bold" }}>
                                                    Seleccione Año
                                                </Typography>
                                                <SliderStepsAnios info={infoGeneral} id_colegio={seleccionColegio} />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                {seleccionColegio && existeAnios && listNumeroMeses !== null && selectNumeroAnio && (
                                    <>
                                        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mb: "45px" }}>
                                            <Grid item xs={12}>
                                                <Typography textAlign="start" sx={{ color: "#373737", fontSize: "12px", fontWeight: "bold" }}>
                                                    Seleccione Mes
                                                </Typography>
                                                <SliderStepsMeses />
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                    <Grid item
                                                        xl={12}
                                                        lg={12}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}>
                                                        <Button
                                                            fullWidth
                                                            disabled={enableButtonPdfEmail}
                                                            size="small"
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<PictureAsPdfIcon />}
                                                            onClick={handleOpenEnviarInforme}
                                                            sx={{ maxWidth: "220px" }}
                                                        >
                                                            Descargar/Enviar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>
                                )}

                            </CardContent>
                        </Card>
                    </Grid>


                    {seleccionColegio && existeAnios && listNumeroMeses !== null && selectNumeroAnio && labelCursosAsistenciaTotalColegio.length !== 0 && (
                        <>
                            <Grid
                                key="grid-low-asistencia-alumnos-mes"
                                ref={lowAsistenciaAlumnosMesRef}
                                item
                                xl={8}
                                lg={8}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{ m: "auto" }}
                            >

                                {/* <Typography variant='h6' sx={{ fontSize: fontSize }}>Menor Asistencia de Alumnos por Mes</Typography> */}
                                <TablaInasistenciaAlumnosMes titulo="Menor Asistencia de Alumnos por Mes" fontSize={fontSize} fontSizeTable={fontSizeTable} info={inasistenciaAlumnoMes} handleVerCurso={handleVerCurso} />

                            </Grid>
                            <Grid
                                key="grid-tabla-top-cursos"
                                ref={tablaTopCursosRef}
                                item
                                xl={4}
                                lg={4}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{ m: "auto" }}
                            >

                                {/* <Typography variant='h6' sx={{ fontSize: fontSize }}>Top de Cursos con Mejor Asistencia</Typography> */}
                                <TablaTopCurso titulo="Top de Cursos con Mejor Asistencia" info={labelCursosAsistenciaTotalColegio} handleVerCurso={handleVerCurso} />

                            </Grid>

                            <div id="grafica-tabla-inasistencia-alumno-general-informe">
                                <Box display={mostrarTablaTopCursos ? 'block' : 'none'}>
                                    {/* <TablaTopCursoInforme info={labelCursosAsistenciaTotalColegio} /> */}
                                    <TablaInasistenciaAlumnosMesInforme fontSize={fontSize} fontSizeTable={fontSizeTable} info={inasistenciaAlumnoMes} />
                                </Box>
                            </div>
                            <div id="grafica-tabla-low-curso-informe">
                                <Box display={mostrarTablaTopCursos ? 'block' : 'none'}>
                                    {/* <Typography variant='h6' sx={{ fontSize: fontSize }}>Ranking de Cursos con Menor Asistencia</Typography> */}
                                    <TablaLowCursoInforme info={labelCursosAsistenciaTotalColegio} />
                                </Box>
                            </div>

                        </>
                    )}
                    <Grid
                        key="grid-grafica-curso-mes"
                        ref={graficaCursoMesRef}
                        item
                        xl={9}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        /* xl={isOpenAcordionRanking ? 8 : 11}
                        lg={isOpenAcordionRanking ? 8 : 11}
                        md={isOpenAcordionRanking ? 12 : 11}
                        sm={isOpenAcordionRanking ? 12 : 11}
                        xs={isOpenAcordionRanking ? 12 : 11} */
                        sx={{ m: "auto" }}
                    >
                        {viewGraficaCurso && labelCursosAsistenciaXcurso && (
                            <Card>
                                <CardContent>
                                    <GraphCurso
                                        mes={nombreMes[selectNumeroMes]}
                                        anio={selectNumeroAnio}
                                        curso={labelCursosAsistenciaXcurso.curso}
                                        dataGraficaXcurso={dataGraficaXcurso}
                                    />
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                    <Grid item
                        xl={3}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        /* xl={isOpenAcordionRanking ? 4 : 1}
                        lg={isOpenAcordionRanking ? 4 : 1}
                        md={isOpenAcordionRanking ? 12 : 1}
                        sm={isOpenAcordionRanking ? 12 : 1}
                        xs={isOpenAcordionRanking ? 12 : 12} */
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} >
                        {viewGraficaCurso && labelCursosAsistenciaXcurso && (
                            <>
                                <Card>
                                    <CardContent>
                                        <Typography variant='h6' sx={{ fontSize: fontSize }}>Alumnos de Curso con Mayor Inasistencia</Typography>
                                        <TablaAlumnosCursoInAsistencia orden="top" info={labelCursosAsistenciaXcurso} handleVerAsistenciaAlumno={handleVerAsistenciaAlumno} handleOpenModalInformacionAlumno={handleOpenModalInformacionAlumno} />
                                        <Button
                                            fullWidth
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<PictureAsPdfIcon />}
                                            sx={{ maxWidth: "220px", marginTop: "20px" }}
                                            onClick={handleOpenEnviarInformeCurso}
                                        >
                                            Descargar/Enviar
                                        </Button>
                                    </CardContent>
                                </Card>
                                {/* <HorizontalAccordion titulo="Tabla Inasistencia de Alumnos" Componente={TablaAlumnosCursoInAsistencia} info={labelCursosAsistenciaXcurso} handleEvento={handleVerAsistenciaAlumno} handleClick={handleAccordionRankingClick} handleOpenModalInformacionAlumno={handleOpenModalInformacionAlumno} /> */}

                                <div id="tabla-full-alumnos-curso-informe">
                                    <Box display={mostrarFullTablaCurso ? 'block' : 'none'}>
                                        <TablaAlumnosCursoInformeFull orden="top" info={labelCursosAsistenciaXcurso} />
                                    </Box>
                                </div>
                            </>
                        )}
                    </Grid>
                    <DialogInformeCurso
                        open={openEnviarInformeCurso}
                        handleClose={handleCloseEnviarInformeCurso}
                        handleEmailEnvioInformeCursoChange={handleEmailEnvioInformeCursoChange}
                        emailEnvioInformeCurso={emailEnvioInformeCurso}
                        cursosSeleccionadosEmail={cursosSeleccionadosEmail}
                        anio={selectNumeroAnio}
                        handleGenerateInformeCursoPDF={handleGenerateInformeCursoPDF}
                        handleEnviarInformeCursos={handleEnviarInformeCursos}
                        statusLoading={isLoading}
                        progressTotal={cursosSeleccionadosEmail.length}
                    />
                    <Grid
                        key="grid-divider-1"
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ m: "auto" }}
                    >
                        <Divider component="div" sx={{ color: "#e0e0e0", marginTop: "30px", marginBottom: "30px" }} />
                    </Grid>
                    {/* <Button variant="outlined" onClick={handleOpenModalInformacionAlumno}>
                        Open full-screen dialog
                    </Button> */}

                    <ModalInformacionAlumno titulo={`Información de Asistencia de Alumno(a),  Mes de ${nombreMes[selectNumeroMes]} ${selectNumeroAnio}`} open={modalOpenInformacionAlumno} handleClose={handleCloseModalInformacionAlumno} >


                        {viewGraficaCurso && labelCursosAsistenciaXcurso && (
                            <>
                                <Grid container alignItems="flex-start" sx={{ margin: "40px", width: "auto !important" }} spacing={2}>

                                    <Grid
                                        key="grid-desc-info-alumno-curso"
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ marginBottom: "20px" }}>
                                        <Typography align="center" variant="h6" sx={{ fontSize: fontSizeTitle }}>Datos Personales y Calendarización de la Asistencia</Typography>
                                        <Typography align="justify" variant="div" sx={{ fontSize: fontSizeDialog }}>En el siguiente gráfico, podemos visualizar los datos personales del estudiante y la distribución de su asistencia en los días del mes. Con ello, se puede detectar si el estudiante incurre en inasistencias recurrentes en algún día en particular y así, tomar las medidas preventivas que pongan en riesgo la perdida de alguna materia del currículum educativo.</Typography>

                                    </Grid>

                                    <Grid
                                        key="grid-tabla-top-down-alumno-curso-1"
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12} sx={{ marginBottom: "20px" }}>

                                        {viewGraficaAlumno && labelAsistenciaAlumnoXcursoMes && (
                                            <>
                                                <div id="tabla-informacion-alumno">
                                                    {/* <TablaAsistenciaAlumnoDiasMesInforme year={selectNumeroAnio} month={selectNumeroMes} alumnoSeleccionadoIdNomina={alumnoSeleccionadoIdNomina} infoAlumnoAsistencia={labelCursosAsistenciaXcurso} diasAsistencia={dataGraficaAlumnoXCursoMes} /> */}
                                                    <TablaInfoAlumno orden="top" establecimiento={nombreColegio} info={infoAlumnoAsistenciaXcursoMes} infoAlumnoAsistencia={labelCursosAsistenciaXcurso} />
                                                </div>
                                                <Button
                                                    fullWidth
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<PictureAsPdfIcon />}
                                                    sx={{ maxWidth: "220px", marginTop: "20px" }}
                                                    onClick={handleOpenEnviarInformeAsistenciaAlumno}
                                                >
                                                    Descargar/Enviar
                                                </Button>
                                            </>
                                        )}
                                    </Grid>


                                    <Grid
                                        key="grid-tabla-calendario-alumno"
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12} >
                                        {viewGraficaAlumno && labelAsistenciaAlumnoXcursoMes && (
                                            <>
                                                <div id="calendario-asistencia-alumno-curso-informe">
                                                    {/* <Typography variant='h6' sx={{ fontSize: fontSize, whiteSpace: 'pre-line' }}>Calendario de Asistencia de Alumno(a),  Mes de {nombreMes[selectNumeroMes]} {selectNumeroAnio}</Typography> */}
                                                    <CalendarioMesAlumno year={selectNumeroAnio} month={selectNumeroMes} diasAsistencia={dataGraficaAlumnoXCursoMes} diasRetirados={labelAsistenciaAlumnoXcursoMes} />
                                                    <LegendBoxes />
                                                </div>
                                            </>
                                        )}
                                    </Grid>

                                    <Grid
                                        key="grid-tabla-curva-asistencia-alumno"
                                        ref={graficaCurvaAsistenciaMesRef}
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}

                                    >
                                        {viewGraficaAlumno && labelAsistenciaAlumnoXcursoMes && diasHabiles && (
                                            <>
                                                <Typography align="center" variant="h6" sx={{ fontSize: fontSizeTitle }}>Gráfico con Curva de Asistencia General</Typography>
                                                <Typography align="justify" variant="div" sx={{ fontSize: fontSizeDialog }}>El siguiente gráfico ofrece una visualización rápida de la curva de asistencia por día durante el mes en observación, lo que facilita la lectura de la calendarización de la asistencia del estudiante, analizada previamente.</Typography>
                                                <div id="curva-asistencia-alumno-curso-mes-informe" style={{ marginTop: "60px" }}>
                                                    <CurvaAsistenciaAlumnoMes diasAsistencia={dataGraficaAlumnoXCursoMes} diasHabiles={diasHabiles} />
                                                </div>
                                            </>
                                        )}
                                    </Grid>

                                    <Grid
                                        key="grid-mapa-alumno-curso"
                                        href={mapaAsistenciaAlumnoMesRef}
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}

                                    >

                                        {infoAlumnoAsistenciaXcursoMes.direccion && (
                                            <>
                                                <Typography align="center" variant="h6" sx={{ fontSize: fontSizeTitle }}>Ubicación geográfica del Estudiante</Typography>
                                                <Typography align="justify" variant="div" sx={{ fontSize: fontSizeDialog }}>La geo-referenciación domiciliaria del estudiante presentada a continuación, es una herramienta complementaria al análisis de la asistencia y retrasos en los que incurra el estudiante durante el mes. Nos permitirá enfocar los esfuerzos y apoyo para mitigar la brecha de distancia de los casos que ésta, sea causal de una problemática asociada a la inasistencia del estudiante.</Typography>
                                                <div id="mapa-asistencia-alumno-curso-mes-informe">
                                                    <GoogleMapsInfoAlumno apiKey={API_KEY_GOOGLEMAPS} id_colegio={seleccionColegio} infoColegio={infoGeneral} infoAlumno={infoAlumnoAsistenciaXcursoMes} />
                                                </div>
                                            </>
                                        )}

                                    </Grid>

                                </Grid>

                            </>

                        )}
                    </ModalInformacionAlumno>


                    <DialogInformeAsistenciaAlumno
                        open={openEnviarInformeAsistenciaAlumno}
                        handleClose={handleCloseEnviarInformeAsistenciaAlumno}
                        handleEmailEnvioInformeAsistenciaAlumnoChange={handleEmailEnvioInformeAsistenciaAlumnoChange}
                        emailEnvioInformeAsistenciaAlumno={emailEnvioInformeAsistenciaAlumno}
                        profesorJefeSeleccionadosEmail={profesorJefeSeleccionadosEmail}
                        infoGeneral={infoGeneral}
                        handleGenerateInformeAsistenciaAlumnoPDF={handleGenerateInformeAsistenciaAlumnoPDF}
                        handlenEnviarInformeAsistenciaAlumno={handlenEnviarInformeAsistenciaAlumno}
                        statusLoading={isLoading}
                        progressTotal={profesorJefeSeleccionadosEmail.length}
                        nombre_alumno={capitalize(infoAlumnoAsistenciaXcursoMes.nombres + " " + infoAlumnoAsistenciaXcursoMes.ape_paterno)}
                        mes={selectNumeroMes}
                        anio={selectNumeroAnio}
                    />
                </Grid>

            </Container >

            {seleccionColegio && existeAnios && listNumeroMeses !== null && selectNumeroAnio && (
                <>
                    {/* <ThemeProvider theme={ThemeDialog}> */}
                    <DialogInformeColegio
                        open={openEnviarInforme}
                        handleClose={handleCloseEnviarInforme}
                        directivos={directivos}
                        emails={emailEnvioInformeColegio}
                        handleEmailsChange={handleEmailEnvioInformeColegioChange}
                        handleGenerateInforme={handleGenerateInformeColegioPDF}
                        handlenEnviarInforme={handlenEnviarInforme}
                        handleDownloadCSV={handleDownloadCSV}
                        statusLoading={isLoading}
                    />
                    {/* </ThemeProvider> */}
                </>
            )}

        </>
    );
}