import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../../contexts/IlumenDataContext";
import { IconButton } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { DataGrid } from "@mui/x-data-grid";

export default function TablaLowCursoInforme({ info }) {
    const tablaLowCurso = [];

    const fontSize = {
        xl: "12px",
        lg: "12px",
        md: "12px",
        sm: "12px",
        xs: "11px",
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 30 },
        {
            field: 'asistencia',
            headerName: 'Asistencia',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <>
                    {params.row.asistencia}%
                </>
            ),
        },
        {
            field: 'curso',
            headerName: 'Curso',
            width: 300,
            editable: false,
        },
    ];

    const rows = [];

    if (Object.keys(info).length !== 0) {
        
        info.forEach((item) => {
            
            let objLowCursos = {
                id: `asistencia-low-${item.id_curso}`,
                id_curso: item.id_curso,
                curso: item.curso,
                asistencia: item.asistencia,

            };

            rows.push(objLowCursos);

        });
    }
    const hiddenColumns = ['id'];
    rows.sort((a, b) => a.asistencia - b.asistencia);
    
    tablaLowCurso.push(
        <React.Fragment key="tabla-low-asistencia-informe">
            <DataGrid
                autoHeight
                autoWidth
                rows={rows}
                columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                }}
                pageSizeOptions={[100]}
                disableRowSelectionOnClick
                rowHeight={30}
                columnHeaderHeight={40}
                sx={{ fontSize: fontSize }}
                hideFooter={true}
            />
        </React.Fragment>
    );
    return tablaLowCurso;
}