import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";

export default function SliderStepsAnios({ info, id_colegio }) {
  const { listNumeroMeses, setListNumeroMeses, setSelectNumeroAnio, setLabelCursosAsistenciaTotalColegio } = useContext(IlumenDataContext);

  let colegio;
  const anios = ["Seleccione Año"];
  

  for (const infoColegio of info) {
    if (infoColegio.colegio.id_colegio === id_colegio) {
      colegio = infoColegio.colegio;
      break;
    }
  }

  const conjuntoAnios = new Set();
  for (const nAnio of colegio.aniosAsistencia) {
    if (!conjuntoAnios.has(nAnio.anio)) {
      conjuntoAnios.add(nAnio.anio);
      anios.push(nAnio.anio);
    }
  }

  function valuetext(value) {
    return `${anios[value]}`;

  }

  const handleSeleccionAnio = async (event, newValue) => {
    let meses = [];
    let mesesTmp = [];

    if (!isNaN(anios[newValue])) {
      setSelectNumeroAnio(anios[newValue]);
      
      for (const nAnio of colegio.aniosAsistencia) {
        if (nAnio.anio === anios[newValue]) {
          mesesTmp.push(nAnio.mes);
        }
      }

      
      meses = mesesTmp.sort((a, b) => a - b);
      setListNumeroMeses(meses);

    } else {
      setListNumeroMeses(null);
      setLabelCursosAsistenciaTotalColegio([]);
    }

  }

  return (
      <Slider
        aria-label="Años de Asistencias"
        defaultValue={0}
        getAriaValueText={valuetext}
        getAriaLabel={valuetext}
        valueLabelFormat={valuetext}
        step={1}
        marks
        min={0}
        max={(anios.length) - 1}
        valueLabelDisplay="auto"
        onChange={handleSeleccionAnio}
      />
  );
}