import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { capitalize } from "../Utils/utils";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = import.meta.env.VITE_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = import.meta.env.VITE_API_EDUTECH_HOST;
const API_EDUTECH_PORT = import.meta.env.VITE_API_EDUTECH_PORT;

export const GeneralDataContext = createContext();

/*
0 = no trabajado
1 = presente
2 = ausente
3 = retirado
*/

export const GeneralDataProvider = ({ children }) => {

    const [ informacionGeneralDashboard, setInformacionGeneralDashboard ] = useState({});
    const [ asistenciaAlumnoAusenteDashboard, setAsistenciaAlumnoAusenteDashboard ] = useState([]);

    const getAsistenciaGeneralTopInasistencia = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_asistencia_general_inasistencia`, {
                withCredentials: true,
            });

            if (response.data.success) {
                //setInfoGeneral(response.data.colegioCurso);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getInformacionGeneral = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_informacion_general`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setInformacionGeneralDashboard(response.data);
                //console.log(response.data)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getInformacionGeneralAsistenciaAlumnoAusente = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/get_asistencia_alumno_ausente`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setAsistenciaAlumnoAusenteDashboard(response.data.asistencia_alumno_ausente)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const generalDataContextValue = {
        getAsistenciaGeneralTopInasistencia,
        getInformacionGeneral,
        informacionGeneralDashboard,
        getInformacionGeneralAsistenciaAlumnoAusente,
        asistenciaAlumnoAusenteDashboard,
        
    };

    return (
        <GeneralDataContext.Provider value={generalDataContextValue}>
            {children}
        </GeneralDataContext.Provider>
    );
};
