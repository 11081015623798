import { createTheme } from '@mui/material/styles';

const base_color = import.meta.env.VITE_BASE_COLOR;

export const ThemeDialog = createTheme({
    palette: {
        primary: {
            main: `#${base_color}`,
        },
    },

    components: {
        MuiDialog: {
            styleOverrides: {
                backdrop: {
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                },
            },
        },
    },
});
