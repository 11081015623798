import React from 'react'

import SelectDirectivo from './SelectDirectivo';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailIcon from '@mui/icons-material/Email';
import Loading from '../Loading';
import { fontSize, fontSizeDialog } from '../../Utils/utils';

export default function DialogInformeColegio({ open, handleClose, directivos, emails, handleEmailsChange, handleGenerateInforme, handlenEnviarInforme, handleDownloadCSV, statusLoading }) {
    return (
        <React.Fragment key="dialog-informe-colegio">

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose(); // Asegúrate de llamar a la función correcta aquí para cerrar el diálogo
                    },
                }}
            >
                <DialogTitle>Enviar Informe de Colegio</DialogTitle>
                <DialogContent>

                    <Typography component="div" sx={{ fontSize: fontSizeDialog }}>
                        Para enviar el informe de asistencia por colegio, que incluye promedios por cursos y tablas de clasificación con las mejores y peores asistencias de curso, es necesario ingresar las direcciones de correo electrónico de los destinatarios o directivos relevantes.
                    </Typography>
                    <Typography component="div" sx={{ fontSize: "10px", fontWeight: "bold" }}>
                        Debe separar adecuadamente cada dirección de correo electrónico mediante una coma (',') para garantizar una distribución efectiva del informe a todos los interesados.
                    </Typography>

                    <SelectDirectivo />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="Dirección de correo electrónico"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={emails}
                        onChange={handleEmailsChange}
                    />


                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mb: "15px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Button fullWidth size="small" onClick={handleClose}>Cancelar</Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={handleGenerateInforme}
                            >
                                Descargar
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                color="secondary"
                                startIcon={<EmailIcon />}
                                onClick={handlenEnviarInforme}
                            >
                                Enviar Informe
                            </Button>
                        </Grid>
                        {/* <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                color="secondary"
                                startIcon={<EmailIcon />}
                                onClick={handleDownloadCSV}
                            >
                                Descargar CSV
                            </Button>
                        </Grid> */}
                    </Grid>
                </DialogActions>
                {statusLoading && (
                    <Loading />
                )}
            </Dialog>
        </React.Fragment>
    )
}
