export const template_intro_informe_colegio_mes = (colegio, mes, anio) => {
    var template_intro = `El informe proporcionado por iLUMEN eduTECH ofrece una visión general sobre la asistencia de los estudiantes del ${colegio} durante el mes de ${mes} de ${anio}. Inicialmente, se entrega un gráfico de la asistencia general de los cursos de la Institución, destacando en color azul el volumen de asistencia con su respectiva referencia porcentual, así como el volumen y porcentaje de inasistencias por curso, en color rojo. Este indicador permitirá una rápida visualización de los cursos que necesitan una evaluación y mejora proactiva de la concurrencia a clases, crucial para la adquisición completa del programa educativo. Se aborda, a continuación, el rendimiento de los estudiantes a través de un listado específico de aquellos con baja asistencia, permitiendo así detectar y atender a estudiantes en situación de riesgo que pueden verse afectados en su aprendizaje y bienestar en general. Finalmente, el informe analiza específicamente aquellos cursos con un promedio de asistencia inferior al deseado, lo que ayuda a localizar grupos que necesitan soporte adicional para fortalecer su participación y dedicación al proceso educativo.`;
    return template_intro;
}

export const template_tabla_ina_informe_colegio_mes = () => {
    var template_intro = `En el siguiente informe, se presenta un ranking detallado donde se enumera a los estudiantes de la institución con los mayores porcentajes de inasistencia durante el período evaluado. Cada entrada en la tabla incluye el nombre del alumno, el curso al que pertenece, el porcentaje total de ausencia y el estado de criticidad asociado. Los estados de criticidad se han asignado en función del volumen de inasistencia y permiten identificar rápidamente aquellos casos que requieren atención inmediata y medidas correctivas para mejorar su asistencia y desempeño académico. Esta información es clave para desarrollar estrategias de intervención personalizadas, que permitan garantizar que todos los estudiantes alcancen su máximo potencial educativo, accediendo al currículum educativo de manera integral.`;
    return template_intro;
}

export const template_tabla_cursos_informe_colegio_mes = () => {
    var template_intro = `El informe a continuación muestra un ranking de los promedios de asistencia de todos los cursos de la institución, ordenados de menor a mayor porcentaje de cumplimiento. Esta disposición permite visualizar e identificar rápidamente a los cursos que requieren mayor atención y reforzamiento en términos de asistencia. Esta herramienta facilitará la toma de decisiones para implementar estrategias de mejora anticipada y seguimiento en aquellos cursos con índices por debajo de los deseados, para mejorar su participación y compromiso con el proceso educativo.`;
    return template_intro;
}

export const template_intro_informe_curso_mes = (mes, anio, curso, asistencia) => {
    var template_intro = `El siguiente informe, proporciona la información de asistencia del curso ${curso}, mes de ${mes} del año ${anio}, con un ${asistencia}% de asistencia. En el gráfico se puede identificar la información de asistencia de la nómina completa de estudiantes que integran el curso, destacando en color azul el volumen de asistencia con su respectiva referencia porcentual, así como el volumen y porcentaje de inasistencias por estudiante, en color rojo.\n\nEsta disposición permite visualizar e identificar rápidamente a los estudiantes que requieren mayor atención y reforzamiento en términos de asistencia. Esta herramienta facilitará la toma de decisiones para implementar programas de intervención dirigidos a los estudiantes con mayores índices de ausencia, para mejorar su participación y compromiso con el proceso educativo, incluyendo revisiones de los métodos de enseñanza y el ambiente en el aula.`;
    return template_intro;
}

export const template_intro_informe_tabla_alumnos_curso_mes = () => {
    var template_intro = `Tabla de alumnos de acuerdo con sus porcentajes de asistencia, de mayor a menor, proporcionando una herramienta útil para identificar rápidamente a aquellos estudiantes que requieren atención especial en términos de su presencia en el colegio. Este conjunto de datos ofrece una visión integral de la asistencia en el curso seleccionado, facilitando el seguimiento y la gestión de la misma.`;
    return template_intro;
}


export const template_intro_informe_datos_alumno_curso_mes = () => {
    var template_intro = `En el siguiente gráfico, podemos visualizar los datos personales del estudiante y la distribución de su asistencia en los días del mes. Con ello, se puede detectar si el estudiante incurre en inasistencias recurrentes en algún día en particular y así, tomar las medidas preventivas que pongan en riesgo la perdida de alguna materia del currículum educativo.`;
    return template_intro;
}

export const template_curva_asistencia_informe_datos_alumno_curso_mes = () => {
    var template_intro = `El siguiente gráfico ofrece una visualización rápida de la curva de asistencia por día durante el mes en observación, lo que facilita la lectura de la calendarización de la asistencia del estudiante, analizada previamente.`;
    return template_intro;
}


export const template_localizacion_asistencia_informe_datos_alumno_curso_mes = () => {
    var template_intro = `La geo-referenciación domiciliaria del estudiante presentada a continuación, es una herramienta complementaria al análisis de la asistencia y retrasos en los que incurra el estudiante durante el mes. Nos permitirá enfocar los esfuerzos y apoyo para mitigar la brecha de distancia de los casos que ésta, sea causal de una problemática asociada a la inasistencia del estudiante.`;
    return template_intro;
}
