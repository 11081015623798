import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    scales: {
        y: {
            ticks: {
                display: false // Oculta los ticks del eje Y
            },
            grid: {
                display: false // Opcional: oculta las líneas de la cuadrícula del eje Y
            }
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: false,
            text: 'Asistencia de Alumno',
        },
    },
};



export default function CurvaAsistenciaAlumnoMes({ diasAsistencia, diasHabiles }) {

    var diaAsistencia = 1;
    var indexDia = 0;
    var labels = [];
    var asistenciaData = [];
    
    for (const asistencia of diasAsistencia.datasets[0].data) {
        if (diasHabiles.includes(diaAsistencia)) {
            labels.push(diasAsistencia.labels[indexDia]);

            if (parseInt(asistencia) == 1) {
                asistenciaData.push(asistencia);
            } else {
                asistenciaData.push(asistencia);
            }
        }

        diaAsistencia += 1;
        indexDia += 1;
    }

    var data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Asistencia',
                data: asistenciaData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <React.Fragment key="curva-asistencia-alumno">
            <Line options={options} data={data} />
        </React.Fragment>
    );
}
