import { createTheme } from '@mui/material/styles';

const base_color = import.meta.env.VITE_BASE_COLOR;

export const ThemeSelectProfesorJefe = createTheme({
    palette: {
        primary: {
            main: `#${base_color}`,
        },
    },

    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: "-6px",
                    left: "10px",
                },
            },
        },
    },
});
