import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react'

import bgpizarron from "../../assets/bg-pizarron.png";
import "./StatCard.css";

const fontTitle = {
    xl: "36px",
    lg: "34px",
    md: "33px",
    sm: "30px",
    xs: "30px",
}

const fontContent = {
    xl: "75px",
    lg: "70px",
    md: "68px",
    sm: "65px",
    xs: "65px",
}

const fontContentAdicional = {
    xl: "17px",
    lg: "17px",
    md: "16px",
    sm: "15px",
    xs: "15px",
}
export default function StatCard({ title, value, preinfoadicional, color, bgcolor, infoadicional }) {
    return (
        <Card style={{
            backgroundImage: `url(${bgpizarron})`,
            backgroundSize: 'cover',
            color: 'white',
        }}>
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" align="left" sx={{ fontFamily: "Chalkboard", fontSize: fontTitle }} >
                    {title}
                </Typography>
                <Typography variant="h4" component="div" sx={{ fontFamily: "Chalkboard", fontSize: fontContent }}>
                    {value}
                </Typography>
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                        whiteSpace: 'pre-line',
                        fontFamily: 'Chalkboard',
                        fontSize: fontContentAdicional,
                    }}
                    align="left"
                >
                    {preinfoadicional && (
                        <span style={{ color: color, backgroundColor: bgcolor, fontWeight: 'bold' }}>
                            {preinfoadicional}
                        </span>
                    )}
                    {preinfoadicional && '\n'}
                    <span>{infoadicional}</span>
                </Typography>
            </CardContent>
        </Card>
    );
};