export const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Agregar encabezados al CSV
    const headers = Object.keys(array[0]).join(',') + '\r\n';
    str += headers;

    // Agregar datos al CSV
    for (const row of array) {
        let line = '';
        for (const key in row) {
            if (line !== '') line += ',';
            line += row[key];
        }
        str += line + '\r\n';
    }

    return str;
}

export const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
