import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider, createTheme, CssBaseline, Container, Grid, Paper, Box, Typography, AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar, Card, CardContent, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Alert } from '@mui/material';
import { blue, indigo } from '@mui/material/colors';
import GoogleMapsRankingInasistencia from '../../components/GoogleMaps/GoogleMapsRankingInasistencia';
import StatCard from '../../components/DashboardContent/StatCard';
import { capitalize, fontSize, fontSizeTable } from '../../Utils/utils';

import { GeneralDataContext } from "../../contexts/GeneralDataContext";
import { GoogleMapsContext } from "../../contexts/GoogleMapsContext";
import { DataGrid } from "@mui/x-data-grid";

import datagridScroll from '../../themes/DataGrid/scroll';

const API_KEY_GOOGLEMAPS = import.meta.env.VITE_GOOGLE_MAPS;

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: indigo[500],
        },
        secondary: {
            main: blue[500],
        },
    },
});

function DashboardContent() {
    const { getInformacionGeneral, getInformacionGeneralAsistenciaAlumnoAusente, informacionGeneralDashboard, asistenciaAlumnoAusenteDashboard } = useContext(GeneralDataContext);
    //const { setMarkers, setMarkersColegios } = useContext(GoogleMapsContext);
    const [cargando, setCargando] = useState(true);

    const [activeMarkerId, setActiveMarkerId] = useState(null);

    const handleMarkerMouseOver = (markerId) => {
        setActiveMarkerId(markerId);
    };

    const handleMarkerMouseOut = () => {
        setActiveMarkerId(null);
    };

    useEffect(() => {
        const cargarDatos = async () => {
            await getInformacionGeneral();
            await getInformacionGeneralAsistenciaAlumnoAusente();
            setCargando(false);
        };

        cargarDatos();
    }, []);

    if (cargando) {
        return <div>Cargando...</div>;
    }

    var info_adicional_colegio = "";
    if (informacionGeneralDashboard) {
        informacionGeneralDashboard.nombre_colegios.map(infocolegio => {
            info_adicional_colegio += capitalize(infocolegio) + "\n";
        })
    }

    var info_adicional_cursos = "";
    if (informacionGeneralDashboard) {
        informacionGeneralDashboard.tipo_ensenanza.map(tipo_ensenanza_glosa => {
            info_adicional_cursos += capitalize(tipo_ensenanza_glosa) + "\n";
        })
    }

    var info_adicional_alumnos = "Total de alumnos registrados en sistema, no considera la asociación de asistencias."
    var info_adicional_anos_asistencia = "Cantidad de años asociados a registros de asistencias de alumnos."

    var alumnos_direcciones = [];

    var rows = [];
    var countAusente = 1;
    if (asistenciaAlumnoAusenteDashboard) {
        //console.log(asistenciaAlumnoAusenteDashboard)
        for (let i = 0; i < asistenciaAlumnoAusenteDashboard.length; i++) {
            const asistenciaAlumnoAusente = asistenciaAlumnoAusenteDashboard[i];
            console.log(asistenciaAlumnoAusente)
            if (asistenciaAlumnoAusente !== null) {

                rows.push({
                    id: countAusente,
                    id_nomina_alumno: asistenciaAlumnoAusente.id_nomina_alumno,
                    nombre: capitalize(asistenciaAlumnoAusente.nombre_alumno),
                    curso: asistenciaAlumnoAusente.curso,
                    colegio: capitalize(asistenciaAlumnoAusente.nombre_colegio),
                    mes: capitalize(asistenciaAlumnoAusente.mes),
                    anio: asistenciaAlumnoAusente.anio,
                    profesor_jefe: capitalize(asistenciaAlumnoAusente.profesor_jefe),
                    inasistencia: asistenciaAlumnoAusente.promedio_ausente
                });

                countAusente += 1; // Aquí debería ser += 1 en lugar de += countAusente
                if (asistenciaAlumnoAusente.direccion !== null) {
                    //console.log(`${asistenciaAlumnoAusente.direccion}, ${asistenciaAlumnoAusente.comuna}`)
                    alumnos_direcciones.push({
                        id_nomina_alumno: asistenciaAlumnoAusente.id_nomina_alumno,
                        direccion: capitalize(`${asistenciaAlumnoAusente.direccion}, ${asistenciaAlumnoAusente.comuna}`),
                        nombre: capitalize(asistenciaAlumnoAusente.nombre_alumno),
                        curso: asistenciaAlumnoAusente.curso,
                        colegio: capitalize(asistenciaAlumnoAusente.nombre_colegio),
                        mes: asistenciaAlumnoAusente.mes,
                        anio: asistenciaAlumnoAusente.anio,
                        inasistencia: asistenciaAlumnoAusente.promedio_ausente,
                    });
                }

            }

            /* if (countAusente === 30) {
                break;
            } */
        }

    }

    //console.log(alumnos_direcciones)

    const columns = [
        { field: 'id', headerName: 'ID', width: 30 },
        { field: 'id_nomina_alumno', headerName: 'id Nomina Alumno', width: 30 },
        {
            field: 'inasistencia',
            headerName: 'Inasistencia',
            width: 90,
            editable: false,
            renderCell: (params) => (
                <>
                    {params.row.inasistencia}%
                </>
            ),
        },
        {
            field: 'curso',
            headerName: 'Curso',
            width: 120,
            editable: false,
        },
        {
            field: 'colegio',
            headerName: 'Colegio',
            width: 120,
            editable: false,
        },
        {
            field: 'nombre',
            headerName: 'Alumno',
            width: 200,
            editable: false,
        },
        {
            field: 'mes',
            headerName: 'Mes',
            width: 100,
            editable: false,
        },
        {
            field: 'anio',
            headerName: 'Año',
            width: 80,
            editable: false,
        },

        {
            field: 'profesor_jefe',
            headerName: 'Profesor Jefe',
            width: 200,
            editable: false,
        },


    ];



    const hiddenColumns = ['id', 'id_nomina_alumno'];
    rows.sort((a, b) => b.inasistencia - a.inasistencia);

    function generarNumeroAleatorio() {
        return Math.floor(Math.random() * 99) + 1;
    }

    var dashboard_asistencia_general = "---";
    var dashboard_asistencia_general_preinfoadicional = "---";
    var dashboard_asistencia_general_infoadicional = "---";
    var dashboard_asistencia_general_colegio = "---";


    //console.log(dashboard_asistencia_general_colegio[0].nombre)

    if (informacionGeneralDashboard.asistenciaGeneralAnual.length !== 0) {
        dashboard_asistencia_general_colegio = informacionGeneralDashboard.colegios.filter(colegio => colegio.id_colegio === informacionGeneralDashboard.asistenciaGeneralAnual[0].id_colegio);
        dashboard_asistencia_general = `${informacionGeneralDashboard.asistenciaGeneralAnual[0].promedio_asistencia.toFixed(2)}%`;
        dashboard_asistencia_general_preinfoadicional = `Asistencia ${informacionGeneralDashboard.asistenciaGeneralAnual[0].promedio_asistencia.toFixed(2)}%`;
        dashboard_asistencia_general_infoadicional = `Promedio de asistencia anual año ${informacionGeneralDashboard.asistenciaGeneralAnual[0].anio}, colegio ${dashboard_asistencia_general_colegio[0].nombre}`;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "80px" }} >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3} key="card-01">
                        <Paper>
                            <StatCard
                                title="COLEGIOS"
                                value={informacionGeneralDashboard.ncolegios}
                                preinfoadicional=" "
                                color='#4caf50'
                                bgcolor='rgb(47 255 55 / 15%)'
                                infoadicional={info_adicional_colegio}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} key="card-02">
                        <Paper>
                            <StatCard
                                title="CURSOS"
                                value={informacionGeneralDashboard.ncursos}
                                preinfoadicional=" "
                                color='#4caf50'
                                bgcolor='rgb(47 255 55 / 15%)'
                                infoadicional={info_adicional_cursos}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} key="card-03">
                        <Paper>
                            <StatCard
                                title="ALUMNOS"
                                value={informacionGeneralDashboard.cantidad_alumnos}
                                preinfoadicional=" "
                                color='#4caf50'
                                bgcolor='rgb(47 255 55 / 15%)'
                                infoadicional={info_adicional_alumnos}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} key="card-04">
                        <Paper>
                            <StatCard
                                title="ASISTENCIA"
                                value={dashboard_asistencia_general}
                                preinfoadicional={dashboard_asistencia_general_preinfoadicional}
                                color='#4caf50'
                                bgcolor='rgb(47 255 55 / 15%)'
                                infoadicional={dashboard_asistencia_general_infoadicional}
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <GoogleMapsRankingInasistencia apiKey={API_KEY_GOOGLEMAPS} colegios={informacionGeneralDashboard.colegios} addresses={alumnos_direcciones} handleMarkerMouseOver={handleMarkerMouseOver} handleMarkerMouseOut={handleMarkerMouseOut} />

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom component="div" sx={{ fontSize: fontSize, fontWeight: 'bold', color: '#333' }}>
                                    ALUMNOS CON RIESGO DE ASISTENCIA
                                </Typography>
                                <React.Fragment key="tabla-informacion-general-dashboard">
                                    <ThemeProvider theme={datagridScroll}>
                                        <DataGrid
                                            autoHeight
                                            autoWidth
                                            rows={rows}
                                            columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
                                            initialState={{
                                                sorting: {
                                                    sortModel: [
                                                        //{ field: 'anio', sort: 'desc' },
                                                        { field: 'mes', sort: 'asc' },
                                                    ],
                                                },
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10]}
                                            disableRowSelectionOnClick
                                            rowHeight={30}
                                            columnHeaderHeight={40}
                                            getRowClassName={(params) => params.row.id_nomina_alumno === activeMarkerId ? 'highlighted-row' : ''}
                                            sx={{
                                                fontSize: fontSizeTable,
                                                '& .highlighted-row': {
                                                    backgroundColor: '#ffbc87', // Estilo de resaltado
                                                },
                                            }}
                                        //hideFooter={true}
                                        />
                                    </ThemeProvider>
                                </React.Fragment>
                                {/* <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nombre Alumno</TableCell>
                                                <TableCell>Curso</TableCell>
                                                <TableCell>Colegio</TableCell>
                                                <TableCell>Mes</TableCell>
                                                <TableCell>Año</TableCell>
                                                <TableCell>Profesor Jefe</TableCell>
                                                <TableCell align="right">Inasistencia</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>{row.nombre}</TableCell>
                                                    <TableCell>{row.curso}</TableCell>
                                                    <TableCell>{row.colegio}</TableCell>
                                                    <TableCell>{row.mes}</TableCell>
                                                    <TableCell>{row.anio}</TableCell>
                                                    <TableCell>{row.profesor_jefe}</TableCell>
                                                    <TableCell align="right"><Alert severity="error">{`${generarNumeroAleatorio()}%`}</Alert></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer> */}
                            </CardContent>
                        </Card>

                    </Grid>


                </Grid>

            </Container>
        </ThemeProvider >
    );
}

export default DashboardContent;
