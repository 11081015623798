import React, { useState, useEffect, useContext } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import iconAlumno from '../../assets/alumno.png';
import iconColegio from '../../assets/colegio.png';
import { capitalize } from '../../Utils/utils';
import { GoogleMapsContext } from "../../contexts/GoogleMapsContext";

const containerStyle = {
    width: '100%',
    height: '350px',
};

const libraries = ['places'];

const GoogleMapsInfoAlumno = ({ apiKey, id_colegio, infoColegio, infoAlumno }) => {

    const { markersInfoAlumno, setMarkersInfoAlumno, markersColegioInfoAlumno, setMarkersColegioInfoAlumno } = useContext(GoogleMapsContext);
    
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [zoomLevel, setZoomLevel] = useState(4);
    

    //    console.log(addresses)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: libraries,
    });

    /* console.log(id_colegio)
    console.log(infoColegio)
    console.log(infoAlumno) */

    var info_colegio = infoColegio.filter(infocolegio => infocolegio.colegio.id_colegio === id_colegio);
    info_colegio = info_colegio[0];

    //console.log(info_colegio);

    let markerIconAlumno;
    let markerIconColegio;


    if (isLoaded) {
        markerIconAlumno = {
            url: iconAlumno,
            scaledSize: new window.google.maps.Size(30, 30),
        };

        markerIconColegio = {
            url: iconColegio,
            scaledSize: new window.google.maps.Size(30, 30),
        };
    }

    const handleMarkerClick = (markerInfo) => {
        alert(`Dirección: ${markerInfo.title}\nNombre: ${markerInfo.nombre}\nCurso: ${markerInfo.curso}\n Id_nomina_alumno: ${markerInfo.id_nomina_alumno}`);
    };

    useEffect(() => {

        if (isLoaded) {
            const geocoder = new window.google.maps.Geocoder();

            //addresses.forEach((address, index) => {
            var direccion_alumno = `${infoAlumno.direccion}, ${infoAlumno.nombre_comuna}`;
            direccion_alumno = direccion_alumno.replace(/[.-]/g, ',');
            //console.log(direccion_alumno)
            /* console.log("direccion_alumno: " + direccion_alumno);
            console.log(infoAlumno) */
            geocoder.geocode({ address: direccion_alumno }, (results, status) => {
                if (status === 'OK') {
                    const position = results[0].geometry.location;
                    console.log(position.lat())
                    console.log(position.lng())
                    setMarkersInfoAlumno([
                        {
                            position,
                            title: direccion_alumno,
                            id_nomina_alumno: infoAlumno.id_nomina_alumno,
                            nombre: capitalize(infoAlumno.nombres),
                            curso: `${infoAlumno.curso_descripcion} - ${infoAlumno.letra}`,
                        },
                    ]);

                    // Establece el centro del mapa en la ubicación del primer marcador
                    //if (index === 0) {
                    //setMapCenter(position);
                    //setZoomLevel(12); // Ajusta este valor para cambiar el nivel de zoom inicial
                    //}
                } else {
                    console.error('Geocode was not successful for the following reason: ' + status);
                }
            });
            //});


            //colegios.forEach((colegio, index) => {
            var colegio_direccion = `${info_colegio.colegio.direccion}, ${info_colegio.colegio.comuna}`;
            /* console.log("colegio: " + colegio_direccion); */
            geocoder.geocode({ address: colegio_direccion }, (results, status) => {
                if (status === 'OK') {
                    console.log(results)
                    const positionColegio = results[0].geometry.location;
                    setMarkersColegioInfoAlumno([
                        {
                            position: positionColegio,
                            title: info_colegio.colegio.direccion,
                            nombre: info_colegio.colegio.nombre,
                        },
                    ]);

                    //if (index === 0) {
                    setMapCenter(positionColegio);
                    setZoomLevel(12); // Ajusta este valor para cambiar el nivel de zoom inicial
                    //}
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            });
            //});
        }
    }, [id_colegio, infoColegio, infoAlumno, isLoaded]);

    //console.log(markersInfoAlumno)
    /*
    console.log(markersColegioInfoAlumno) */
    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={zoomLevel}>
            {markersInfoAlumno.map((marker, index) => (
                <Marker icon={markerIconAlumno} key={index} position={marker.position} title={marker.nombre + " - " + marker.curso}  /*  onMouseOver={() => handleMarkerMouseOver(marker.id_nomina_alumno)} onMouseOut={handleMarkerMouseOut} */ />
            ))}

            {markersColegioInfoAlumno.map((markerColegio, index) => (
                <Marker icon={markerIconColegio} key={index} position={markerColegio.position} title={markerColegio.nombre} />
            ))}
        </GoogleMap>
    ) : (
        <div>Loading...</div>
    );
};

export default GoogleMapsInfoAlumno;
