import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";
import { IconButton } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { DataGrid } from "@mui/x-data-grid";
import { fontSizeTable } from "../../Utils/utils";

export default function TablaAlumnosCursoInAsistencia({ info, handleVerAsistenciaAlumno, orden, handleOpenModalInformacionAlumno }) {

    function capitalize(frase) {
        return frase
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }


    const tablaAsistenciaCurso = [];

    const columns = [
        { field: 'id', headerName: 'ID', width: 30 },
        {
            field: 'inasistencia',
            headerName: 'Inasistencia',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <>
                    {params.row.inasistencia}%
                </>
            ),
        },
        {
            field: 'opciones',
            headerName: '',
            width: 50,
            editable: false,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="Ver Información de Alumno"
                        onClick={() =>
                            handleOpenModalInformacionAlumno(`${params.row.id_nomina_alumno}`)
                            
                        }
                    >
                        <ZoomInIcon />
                    </IconButton>
                </>
            ),
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <>
                    {capitalize(params.row.nombre)}
                </>
            ),
        },
        {
            field: 'ape_paterno',
            headerName: 'Apellido Paterno',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <>
                    {capitalize(params.row.ape_paterno)}
                </>
            ),
        },
        {
            field: 'ape_materno',
            headerName: 'Apellido Materno',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <>
                    {capitalize(params.row.ape_materno)}
                </>
            ),
        },


    ];

    const rows = [];

    if (Object.keys(info).length !== 0) {

        info.alumnos.forEach((item) => {
            //console.log(item)
            let objAsistenciaAlumnosCurso = {
                id: `asistencia-alumno-curso-${item.id_nomina_alumno}`,
                id_nomina_alumno: item.id_nomina_alumno,
                nombre: item.nombres,
                ape_paterno: item.ape_paterno,
                ape_materno: item.ape_materno,
                inasistencia: item.ausente_mes,
                asistencia: item.asistencia_mes,
            };

            rows.push(objAsistenciaAlumnosCurso);

        });
    }
    const hiddenColumns = ['id'];

    if (orden === "top") {
        rows.sort((a, b) => b.inasistencia - a.inasistencia);
    }

    if (orden === "low") {
        rows.sort((a, b) => a.inasistencia - b.inasistencia);
    }

    //console.log(rows)
    tablaAsistenciaCurso.push(
        <React.Fragment key="tabla-asistencia-alumno-curso">
            <DataGrid
                autoHeight
                autoWidth
                rows={rows}
                columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
                initialState={{
                    sortModel: [
                        {
                            field: 'inasistencia',
                            sort: 'asc',
                        },
                    ],
                    pagination: {
                        paginationModel: {
                            pageSize: 16,
                        },
                    },
                }}
                pageSizeOptions={[16]}
                disableRowSelectionOnClick
                rowHeight={30}
                columnHeaderHeight={40}
                sx={{ fontSize: fontSizeTable }}
            />
        </React.Fragment>
    );
    return tablaAsistenciaCurso;
}