import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { capitalize } from "../Utils/utils";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = import.meta.env.VITE_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = import.meta.env.VITE_API_EDUTECH_HOST;
const API_EDUTECH_PORT = import.meta.env.VITE_API_EDUTECH_PORT;

export const InformeIAContext = createContext();

export const InformeIAProvider = ({ children }) => {

    const getAnalisisCualitativoInformeGeneral = async (csv) => {
        const form = { csv };
        try {
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/ilumen/ia/analisis_cualitativo_informe_general`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                return response.data.reporte;
            }
        } catch (e) {
            console.log(e);
        }
    };


    const InformeIAContextValue = {
        getAnalisisCualitativoInformeGeneral,
    };

    return (
        <InformeIAContext.Provider value={InformeIAContextValue}>
            {children}
        </InformeIAContext.Provider>
    );
};
