import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = import.meta.env.VITE_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = import.meta.env.VITE_API_EDUTECH_HOST;
const API_EDUTECH_PORT = import.meta.env.VITE_API_EDUTECH_PORT;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [perfilUsuario, setPerfilUsuario] = useState(null);
  const [perfilImagen, setPerfilImagen] = useState(null);

  const login = async (credentials) => {
    try {
      const response = await axios.post(
        `${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/login`,
        { ...credentials },
        { withCredentials: true }
      );

      if (response.status === 200 && response.data.success) {
        setIsAuthenticated(response.data.success);
        window.location.href = "/";
      } else {
        console.log("AuthContext login:"+response.data.message);
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }
  };

  const logout = async () => {
    try {
      const { response } = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/logout`, {
        withCredentials: true,
      });

      if (response.data.success) {
        Cookies.remove("token");
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }

    setIsAuthenticated(null);
    Cookies.remove("token");
    window.location.href = "/login";
  };

  const checkSession = async () => {
    const formtoken = {};
    try {
      const { data } = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/verify`,formtoken, { withCredentials: true });
      setIsAuthenticated(data.status);
      //console.log(data.status)
      setPerfilUsuario(data.username);
      setPerfilImagen(data.perfil_imagen);
      if ( !data.status ) {
        setIsAuthenticated(false);
      }

    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkSession();

    const sessionTimer = setInterval(() => {
      checkSession();
    }, 60 * 1000);

    return () => {
      clearInterval(sessionTimer);
    };
  }, []);

  const authContextValue = {
    isAuthenticated,
    setIsAuthenticated,
    checkSession,
    login,
    logout,
    perfilUsuario,
    setPerfilUsuario,
    perfilImagen,
    setPerfilImagen
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
