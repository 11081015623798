import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { capitalize } from "../Utils/utils";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = import.meta.env.VITE_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = import.meta.env.VITE_API_EDUTECH_HOST;
const API_EDUTECH_PORT = import.meta.env.VITE_API_EDUTECH_PORT;

export const GoogleMapsContext = createContext();

export const GoogleMapsProvider = ({ children }) => {
    /* dashboard */
    const [markers, setMarkers] = useState([]);
    const [markersColegios, setMarkersColegios] = useState([]);

    /* mapa info alumno */
    const [markersInfoAlumno, setMarkersInfoAlumno] = useState([]);
    const [markersColegioInfoAlumno, setMarkersColegioInfoAlumno] = useState([]);

    

    const GoogleMapsContextValue = {
        markersInfoAlumno,
        setMarkersInfoAlumno,
        markersColegioInfoAlumno,
        setMarkersColegioInfoAlumno,
        markers,
        setMarkers,
        markersColegios,
        setMarkersColegios
    };

    return (
        <GoogleMapsContext.Provider value={GoogleMapsContextValue}>
            {children}
        </GoogleMapsContext.Provider>
    );
};
