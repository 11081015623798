import React, { useState, useContext } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { ThemeSelectDirectivo } from './ThemeSelectDirectivo';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";

export default function  SelectDirectivosCursos({ anio }) {

    const {
        infoGeneral, cursosSeleccionadosEmail, setCursosSeleccionadosEmail, seleccionColegio,
    } = useContext(IlumenDataContext);

    const handleChange = (event) => {

        const {
            target: { value },
        } = event;
        setCursosSeleccionadosEmail(
            // En caso de ser un objeto, necesitarías procesar para obtener IDs o nombres
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getCursosColegio(id_colegio, anio) {
        let cursos = [];
        if (Array.isArray(infoGeneral)) {
            infoGeneral.map((infoColegio) => {
                if (infoColegio.colegio.id_colegio === id_colegio ) {
                    //cursos = infoColegio.colegio.cursos;
                    cursos = infoColegio.colegio.cursos.filter(infocurso => infocurso.anio === anio);
                }

            });
        }

        return cursos;
    }

    var infoCursos = getCursosColegio(seleccionColegio, anio);
    
    return (
        <ThemeProvider theme={ThemeSelectDirectivo}>
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel variant="standard" htmlFor="cursos">
                    Cursos
                </InputLabel>
                <Select
                    multiple
                    defaultValue={[]}
                    value={cursosSeleccionadosEmail}
                    onChange={handleChange}
                    input={<OutlinedInput label="Cursos" id="select-multiple-cursos" />}
                    renderValue={(selected) => {
                        return selected.map((id) => {
                            const curso = infoCursos.find((curso) => curso.id_curso === id);
                            return curso ? `${curso.nombre_curso}/${curso.letra}` : '';
                        }).join(', ');
                    }}
                >
                    {infoCursos.map((curso) => (
                        <MenuItem key={curso.id_curso} value={curso.id_curso}>
                            <Checkbox checked={cursosSeleccionadosEmail.indexOf(curso.id_curso) > -1} />
                            <ListItemText primary={curso.nombre_curso + "/" + curso.letra} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
};

