import React, { useState, useContext } from 'react';
import { CircularProgress, Box, Typography, Button, LinearProgress } from '@mui/material';
import { IlumenDataContext } from "../contexts/IlumenDataContext";
import { keyframes } from '@mui/system';

const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

export default function LoadingStep({ total }) {
    const { progress } = useContext(IlumenDataContext);
    //const [progress, setProgress] = useState(1);

    // Simula la actualización del progreso
    /* const updateProgress = () => {
        setProgress((prevProgress) => (prevProgress < total ? prevProgress + 1 : prevProgress));
    }; */

    return (
        <>
            <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="rgba(0, 0, 0, 0.7)"
                zIndex="modal"
            >
                <CircularProgress variant="determinate" value={(progress / total) * 100} />
                
            </Box>
            <Box
                position="fixed"
                top={50}
                left={5}
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex="modal"
            >
                <Typography sx={{ marginTop:"20px", color: 'white', mt: 2, '& #dot1': { animation: `${blink} 1.1s infinite` }, '& #dot2': { animation: `${blink} 1.2s infinite` }, '& #dot3': { animation: `${blink} 1.3s infinite` } }}>
                    Procesando
                    <span id="dot1">.</span><span id="dot2">.</span><span id="dot3">.</span>
                </Typography>
                
            </Box>
            

        </>
    );
}
