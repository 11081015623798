import React, { useState, useContext } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { ThemeSelectDirectivo } from './ThemeSelectDirectivo';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";
import { capitalize } from '../../Utils/utils';

export default function SelectDirectivo() {

    const {
        directivosSeleccionadosNombre, setDirectivosSeleccionadosNombre, directivos
    } = useContext(IlumenDataContext);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        console.log(event);
        setDirectivosSeleccionadosNombre(
            // En caso de ser un objeto, necesitarías procesar para obtener IDs o nombres
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <React.Fragment key="select-directivo-dialog">
            <ThemeProvider theme={ThemeSelectDirectivo}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel variant="standard" htmlFor="directivos">
                        Directivos
                    </InputLabel>
                    <Select
                        multiple
                        defaultValue={[]}
                        value={directivosSeleccionadosNombre}
                        onChange={handleChange}
                        input={<OutlinedInput label="Directivos" id="select-multiple-directivos" />}
                        renderValue={(selected) => {
                            return selected.map((id) => {
                                const directivo = directivos.find((directivo) => directivo.nombre === id);
                                return directivo ? `${capitalize(directivo.nombre)}` : '';
                            }).join(', ');
                        }}
                    >
                        {directivos.map((directivo) => (
                            <MenuItem key={directivo.id_directivo} value={directivo.nombre}>
                                <Checkbox checked={directivosSeleccionadosNombre.indexOf(directivo.nombre) > -1} />
                                <ListItemText primary={ directivo.cargo === null || directivo.cargo === "" ? capitalize(directivo.nombre) : capitalize(`${directivo.nombre} (${directivo.cargo})`) } />
                                 
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ThemeProvider>
        </React.Fragment>
    );
};

