import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../contexts/IlumenDataContext";

export default function SliderStepsMeses() {

  const {
    setIsLoading,
    listNumeroMeses,
    selectNumeroAnio,
    seleccionColegio,
    getAsistenciaXMesAnio,
    setLabelCursosAsistenciaTotalColegio,
    setSelectNumeroMes,
    setViewGraficaCurso,
    setLabelCursosAsistenciaXcurso,
    setViewGraficaAlumno,
    setLabelAsistenciaAlumnoXcursoMes,
    setEnableButtonPdfEmail
  } = useContext(IlumenDataContext);

  const meses = listNumeroMeses;

  const nombreMes = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];


  function valuetext(value) {
    //console.log(value);
    return `${nombreMes[value]}`;

  }

  const handleSeleccionMes = async (event, newValue) => {
    let currentMes = parseInt(newValue) + 1;

    if (!isNaN(newValue)) {
      /* console.log(listNumeroMeses);
      console.log(currentMes); */
      if (listNumeroMeses.includes(currentMes)) {
        setIsLoading(true);
        console.log(`El valor ${currentMes} está presente en el array.`);
        await getAsistenciaXMesAnio(currentMes, selectNumeroAnio, seleccionColegio);
        setSelectNumeroMes(currentMes);
        setViewGraficaCurso(false)
        setLabelCursosAsistenciaXcurso([])
        setViewGraficaAlumno(false);
        setLabelAsistenciaAlumnoXcursoMes([])
        setEnableButtonPdfEmail(false);
      } else {
        setLabelCursosAsistenciaTotalColegio([]);
        setSelectNumeroMes(null);
        setLabelCursosAsistenciaXcurso([]);
        setViewGraficaCurso(null);
        setViewGraficaAlumno(false);
        setLabelAsistenciaAlumnoXcursoMes([]);
        setEnableButtonPdfEmail(true);
      }
    }

  }

  return (
    <Slider
      aria-label="Meses Asistencia"
      defaultValue={0}
      getAriaValueText={valuetext}
      getAriaLabel={valuetext}
      valueLabelFormat={valuetext}
      step={1}
      marks
      min={0}
      max={11}
      valueLabelDisplay="auto"
      onChange={handleSeleccionMes}
    />
  );
}