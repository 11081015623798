import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box } from '@mui/material';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: "white"
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: "#FF0000" }} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ color: "#FFA500" }} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ color: "#FFFF00" }} />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={{ color: "#008000" }} />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={{ color: "#00FF00" }} />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function RatingFaces({ activeIcon }) {
  const [ratingValue, setRatingValue] = useState(activeIcon);

  useEffect(() => {
    setRatingValue(activeIcon);
  }, [activeIcon]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 45%)",
        marginTop: "10px",
      }}
    >
      <StyledRating
        name="highlight-selected-only"
        value={ratingValue}
        onChange={(event, newValue) => {
          setRatingValue(newValue);
        }}
        IconContainerComponent={IconContainer}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly
        sx={{ marginTop: "10px" }}
      />
    </Box>
  );
}

RatingFaces.propTypes = {
  activeIcon: PropTypes.number,
};

RatingFaces.defaultProps = {
  activeIcon: 0,
};
