// Importa React y los componentes de Material-UI que necesitarás
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

// Crea componentes estilizados para los cuadros de asistencia e inasistencia
const AttendanceBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(3),
  backgroundColor: '#5ac55f',
}));

const AbsenceBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(3),
  backgroundColor: '#d95454',
}));

const RetiradoBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(3),
  backgroundColor: '#ebc50b',
}));

const fontSize = {
  xl: "12px",
  lg: "12px",
  md: "12px",
  sm: "12px",
  xs: "11px",
}

// Componente que renderiza los cuadros con leyendas
const LegendBoxes = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={2} sx={{marginTop: "20px", marginBottom: "20px"}}>
      <Box display="flex" alignItems="center" gap={1}>
        <AttendanceBox />
        <Typography sx={{fontSize: fontSize}}>Asistencia</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <AbsenceBox />
        <Typography sx={{fontSize: fontSize}}>Inasistencia</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <RetiradoBox />
        <Typography sx={{fontSize: fontSize}}>Retirado</Typography>
      </Box>
    </Box>
  );
};

export default LegendBoxes;
