import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export default function Loading() {
    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0, 0, 0, 0.7)"
            zIndex="modal"
        >
            <CircularProgress />
        </Box>
    )
}
