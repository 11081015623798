import React from 'react';
import { Dialog, CircularProgress, Box, DialogContent, Typography, DialogTitle, Grid, Button, DialogActions, TextField } from '@mui/material';
import SelectDirectivo from './SelectDirectivo';
import SelectDirectivosCursos from './SelectDirectivosCursos';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailIcon from '@mui/icons-material/Email';
import Loading from '../Loading';
import LoadingStep from '../LoadingStep';
import { fontSize, fontSizeDialog } from '../../Utils/utils';



export default function DialogInformeCurso({ open, handleClose, anio, emailEnvioInformeCurso, handleEmailEnvioInformeCursoChange, cursosSeleccionadosEmail, handleGenerateInformeCursoPDF, handleEnviarInformeCursos, statusLoading, progressTotal }) {

  return (
    <Dialog open={open} onClose={handleClose}>

      <DialogTitle>Enviar Informe de Curso</DialogTitle>

      <DialogContent>

        <Typography component="div" sx={{ fontSize: fontSizeDialog }}>
          Para enviar el informe de asistencia por curso, que detalla los promedios de asistencia por alumno, así como tablas de clasificación destacando las mejores y peores asistencias individuales dentro del curso seleccionado, es necesario introducir las direcciones de correo electrónico correspondientes a los destinatarios involucrados, sean estos directivos relevantes o el profesor jefe del curso.
        </Typography>
        <Typography component="div" sx={{ fontSize: "10px", fontWeight: "bold" }}>
          Debe separar adecuadamente cada dirección de correo electrónico mediante una coma (',') para garantizar una distribución efectiva del informe a todos los interesados.
        </Typography>

        <SelectDirectivo />
        <SelectDirectivosCursos anio={anio} />
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Dirección de correo electrónico"
          type="email"
          fullWidth
          variant="standard"
          value={emailEnvioInformeCurso}
          onChange={handleEmailEnvioInformeCursoChange}
        />
        <input
          id="id_cursos"
          name="id_cursos"
          type="hidden"
          value={cursosSeleccionadosEmail}
        />


      </DialogContent>
      <DialogActions>

        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mb: "15px" }}>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Button fullWidth size="small" onClick={handleClose}>Cancelar</Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="primary"
              startIcon={<PictureAsPdfIcon />}
              onClick={handleGenerateInformeCursoPDF}
            >
              Descargar
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<EmailIcon />}
              onClick={handleEnviarInformeCursos}
            >
              Enviar Informe
            </Button>
          </Grid>

        </Grid>

      </DialogActions>
      {statusLoading && (
        <LoadingStep total={progressTotal} />
      )}
    </Dialog>
  );
}
