import React, { useState, useContext } from 'react';
import { Dialog, CircularProgress, Box, DialogContent, Typography, DialogTitle, Grid, Button, DialogActions, TextField } from '@mui/material';
import SelectDirectivo from './SelectDirectivo';
import SelectDirectivosCursos from './SelectDirectivosCursos';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailIcon from '@mui/icons-material/Email';
import Loading from '../Loading';
import LoadingStep from '../LoadingStep';
import SelectProfesorJefe from './SelectProfesorJefe';

const fontSizeDialog = {
  xl: "14px",
  lg: "14px",
  md: "14px",
  sm: "14px",
  xs: "14px",
}

export default function DialogInformeAsistenciaAlumno({ open, handleClose, infoGeneral, emailEnvioInformeAsistenciaAlumno, handleEmailEnvioInformeAsistenciaAlumnoChange, profesorJefeSeleccionadosEmail, handleGenerateInformeAsistenciaAlumnoPDF, handleEnviarInformeCursos, statusLoading, progressTotal, nombre_alumno, mes, anio, handlenEnviarInformeAsistenciaAlumno }) {

  const nombreMes = ['',
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  


  function isNumeric(value) {
    return !isNaN(value - parseFloat(value));
  }

  return (
    <Dialog open={open} onClose={handleClose}>

      <DialogTitle>Enviar Informe de Asistencia de Alumno</DialogTitle>

      <DialogContent>

        <Typography component="div" sx={{ fontSize: fontSizeDialog }}>
          La aplicación permite enviar el calendario del estudiante <span style={{ fontWeight: "bold" }}>{nombre_alumno}</span> correspondiente al mes de <span style={{ fontWeight: "bold" }}>{nombreMes[mes]}</span> del año <span style={{ fontWeight: "bold" }}>{anio}</span>, el cual refleja el registro de asistencia escolar. Este informe puede ser remitido a la dirección del establecimiento, al profesor jefe y a los apoderados para su conocimiento y seguimiento.
        </Typography>
        <Typography component="div" sx={{ fontSize: "10px", fontWeight: "bold" }}>
          Debe separar adecuadamente cada dirección de correo electrónico mediante una coma (',') para garantizar una distribución efectiva del informe a todos los interesados.
        </Typography>

        <SelectDirectivo />
        <SelectProfesorJefe />
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Dirección de correo electrónico"
          type="email"
          fullWidth
          variant="standard"
          value={emailEnvioInformeAsistenciaAlumno}
          onChange={handleEmailEnvioInformeAsistenciaAlumnoChange}
        />
        <input
          id="id_profesor_jefe"
          name="id_profesores"
          type="hidden"
          value={profesorJefeSeleccionadosEmail}
        />


      </DialogContent>
      <DialogActions>

        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mb: "15px" }}>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Button fullWidth size="small" onClick={handleClose}>Cancelar</Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="primary"
              startIcon={<PictureAsPdfIcon />}
              onClick={handleGenerateInformeAsistenciaAlumnoPDF}
            >
              Descargar
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<EmailIcon />}
              onClick={handlenEnviarInformeAsistenciaAlumno}
            >
              Enviar Informe
            </Button>
          </Grid>

        </Grid>

      </DialogActions>
      {statusLoading && (
        <LoadingStep total={progressTotal} />
      )}
    </Dialog>
  );
}
