import React, { useState } from 'react';
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalInformacionAlumno({ titulo, open, handleClose, children }) {
  return (
    <Dialog
      fullScreen
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: "#333" }} >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {titulo}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}

export default ModalInformacionAlumno;
