// GoogleMapsMultipleMarkers.jsx
import React, { useState, useEffect, useContext } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { GoogleMapsContext } from "../../contexts/GoogleMapsContext";

import iconAlumno from '../../assets/alumno.png';
import iconColegio from '../../assets/colegio.png';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const libraries = ['places'];

const GoogleMapsMultipleMarkers = ({ apiKey, colegios, addresses, handleMarkerMouseOver, handleMarkerMouseOut }) => {

    const { markers, setMarkers, markersColegios, setMarkersColegios } = useContext(GoogleMapsContext);

    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [zoomLevel, setZoomLevel] = useState(4);

    //    console.log(addresses)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: libraries,
    });


    let markerIconAlumno;
    let markerIconColegio;
    if (isLoaded) {
        markerIconAlumno = {
            url: iconAlumno,
            scaledSize: new window.google.maps.Size(30, 30),
        };

        markerIconColegio = {
            url: iconColegio,
            scaledSize: new window.google.maps.Size(30, 30),
        };
    }

    const handleMarkerClick = (markerInfo) => {
        alert(`Dirección: ${markerInfo.title}\nNombre: ${markerInfo.nombre}\nCurso: ${markerInfo.curso}\n Id_nomina_alumno: ${markerInfo.id_nomina_alumno}`);
    };

    useEffect(() => {

        if (isLoaded) {
            const geocoder = new window.google.maps.Geocoder();

            addresses.forEach((address, index) => {
                var direccion_alumno = address.direccion;
                direccion_alumno = direccion_alumno.replace(/[.-]/g, ',');

                geocoder.geocode({ address: direccion_alumno }, (results, status) => {
                    if (status === 'OK') {
                        const position = results[0].geometry.location;
                        setMarkers((prevMarkers) => [
                            ...prevMarkers,
                            {
                                position,
                                title: address.direccion,
                                id_nomina_alumno: address.id_nomina_alumno,
                                nombre: address.nombre,
                                curso: address.curso,
                            },
                        ]);

                        // Establece el centro del mapa en la ubicación del primer marcador
                        if (index === 0) {
                            //setMapCenter(position);
                            //setZoomLevel(12); // Ajusta este valor para cambiar el nivel de zoom inicial
                        }
                    } else {
                        console.log('Geocode was not successful for the following reason: ' + status);
                    }
                });
            });


            colegios.forEach((colegio, index) => {
                var colegio_direccion = `${colegio.direccion}, ${colegio.comuna}`;
                geocoder.geocode({ address: colegio_direccion }, (results, status) => {
                    if (status === 'OK') {
                        
                        const positionColegio = results[0].geometry.location;
                        setMarkersColegios((prevMarkersColegios) => [
                            ...prevMarkersColegios,
                            {
                                position: positionColegio,
                                title: colegio.direccion,
                                nombre: colegio.nombre,
                            },
                        ]);

                        if (index === 0) {
                            setMapCenter(positionColegio);
                            setZoomLevel(12); // Ajusta este valor para cambiar el nivel de zoom inicial
                        }
                    } else {
                        console.error('Geocode was not successful for the following reason: ' + status);
                    }
                });
            });
        }
    }, [addresses, colegios, isLoaded]);

    /* console.log(markers)
    console.log(markersColegios) */
    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={zoomLevel}>
            {markers.map((marker, index) => (
                <Marker icon={markerIconAlumno} key={index} position={marker.position} title={marker.nombre + " - " + marker.curso} onClick={() => handleMarkerClick(marker)} /*  onMouseOver={() => handleMarkerMouseOver(marker.id_nomina_alumno)} onMouseOut={handleMarkerMouseOut} */ />
            ))}

            {markersColegios.map((markerColegio, index) => (
                <Marker icon={markerIconColegio} key={index} position={markerColegio.position} title={markerColegio.nombre} />
            ))}
        </GoogleMap>
    ) : (
        <div>Loading...</div>
    );
};

export default GoogleMapsMultipleMarkers;
