import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../../../contexts/IlumenDataContext";
import { Alert, IconButton, Typography } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { DataGrid } from "@mui/x-data-grid";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { calcularEdad, capitalize, categoriaAsistencia, fontSize, fontSizeTable } from '../../../../Utils/utils';


export default function TablaInfoAlumno({ orden, establecimiento, info, infoAlumnoAsistencia }) {

    var arrAlumnos = infoAlumnoAsistencia.alumnos;
    //console.log(arrAlumnos);

    var info_alumno = arrAlumnos.filter(infoalumno => infoalumno.id_nomina_alumno === info.id_nomina_alumno);
    info_alumno = info_alumno[0];

    var promedio_asistencia = parseFloat(info_alumno.asistencia_mes);
    var categoria_asistencia = categoriaAsistencia(info_alumno.asistencia_mes);
    //console.log(promedio_asistencia);

    /* formato nacimiento */
    const fechaNacimiento = info.fecha_nacimiento;
    const date = new Date(fechaNacimiento);
    const fechaNacimientoFormateado = date.toLocaleDateString('es-CL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    /* formato incorporacion curso */
    const fechaIncorporacionCurso = info.fecha_incorporacion_curso;
    const dateIncorporacionCurso = new Date(fechaIncorporacionCurso);
    const fechaIncorporacionCursoFormateado = dateIncorporacionCurso.toLocaleDateString('es-CL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    /* formato fecha de retiro curso */
    const fechaRetiroCurso = info.fecha_retiro;
    const dateRetiroCurso = new Date(fechaRetiroCurso);
    let fechaRetiroCursoFormateado = dateRetiroCurso.toLocaleDateString('es-CL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const fechaRetiroCursoAnio = dateRetiroCurso.toLocaleDateString('es-CL', {
        year: 'numeric',
    });

    if (fechaRetiroCursoAnio < 1900) {
        fechaRetiroCursoFormateado = "---";
    }

    /* edad alumno */
    const edad = calcularEdad(info.fecha_nacimiento);

    /* comuna residencia */
    let comuna_residencia = "---";
    if (info.nombre_comuna === null || info.nombre_comuna == "" || (info.nombre_comuna).length <= 2 ) {
        comuna_residencia = "---";
    } else {
        comuna_residencia = capitalize(`${info.nombre_comuna}`);
    }

    /* direccion */
    let direccion = "---";
    if (info.direccion === null || info.direccion == "" || (info.direccion).length <= 2 ) {
        direccion = "---";
    } else {
        direccion = info.direccion;
    }

    /* telefono */
    let telefono = "---";
    if (info.telefono === null || info.telefono == "" || (info.telefono).length <= 2 ) {
        telefono = "---";
    } else {
        telefono = info.telefono;
    }

    /* email */
    let email = "---";
    if (info.email === null || info.email == "" || (info.email).length <= 2 ) {
        email = "---";
    } else {
        email = info.email;
    }
/* 
    console.log(typeof info.direccion)
    console.log(info.direccion) */

    var categoria_asistencia = categoriaAsistencia(info_alumno.asistencia_mes);

    const columns = [
        {
            field: 'info',
            headerName: '',
            width: 200,
            headerAlign: 'left',
            hide: true,
            renderCell: (params) => (
                <>
                    <strong>
                        {params.row.campo}
                    </strong>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', paddingLeft: '16px' }}>{params.row.campo}</div>
                    <div style={{ flex: 1, textAlign: 'right', paddingRight: '16px' }}>{params.row.valor}</div>
                </div> */}
                </>
            ),
        },
        {
            field: 'info2',
            headerName: '',
            width: 200,
            headerAlign: 'center',
            hide: true,
            renderCell: (params) => (
                <>
                    {params.row.valor}
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', paddingLeft: '16px' }}>{params.row.campo}</div>
                    <div style={{ flex: 1, textAlign: 'right', paddingRight: '16px' }}>{params.row.valor}</div>
                </div> */}
                </>
            ),
        },
    ];

    const rows = [
        { id: 1, campo: 'Nombre', valor: `${capitalize(info.nombres)} ${capitalize(info.ape_paterno)} ${capitalize(info.ape_materno)}` },
        { id: 2, campo: 'Run', valor: `${info.run}-${info.digito_ve}` },
        { id: 3, campo: 'Asistencia', valor: `${promedio_asistencia}%` },
        { id: 4, campo: 'Fecha de Nacimiento', valor: `${fechaNacimientoFormateado}` },
        { id: 5, campo: 'Edad', valor: `${edad} Años` },
        { id: 6, campo: 'Curso', valor: `${info.curso_descripcion} - ${info.letra}` },
        { id: 7, campo: 'Establecimiento', valor: `${capitalize(establecimiento)}` },
        { id: 8, campo: 'Fecha de Incorporación', valor: `${fechaIncorporacionCursoFormateado}` },
        { id: 9, campo: 'Dirección', valor: `${capitalize(direccion)}` },
        { id: 10, campo: 'Comuna de Residencia', valor: comuna_residencia },
        { id: 11, campo: 'Teléfono', valor: `${telefono}` },
        { id: 12, campo: 'Email', valor: `${email.toLowerCase()}` },
        { id: 13, campo: 'Fecha de Retiro', valor: fechaRetiroCursoFormateado },
    ];

    const hiddenColumns = ['id'];

    if (orden === "top") {
        rows.sort((a, b) => b.asistencia - a.asistencia);
    }

    if (orden === "low") {
        rows.sort((a, b) => a.asistencia - b.asistencia);
    }

    return (
        <React.Fragment key="tabla-informacion-alumno-curso">
            {/* <DataGrid
                autoHeight
                autoWidth
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick
                rowHeight={30}
                columnHeaderHeight={40}
                sx={{ fontSize: fontSizeTable }}
                hideFooter={true}
            /> */}

            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell><strong>{row.campo}</strong></TableCell>
                                <TableCell>{row.valor}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='p' sx={{ fontSize: fontSize, textAlign: "justify" }}>
                <Alert severity={categoria_asistencia.alert}>
                    El alumno <strong>{capitalize(info.nombres)} {capitalize(info.ape_paterno)} {capitalize(info.ape_materno)}</strong> tuvo una {categoria_asistencia.categoria}
                </Alert>
            </Typography>
        </React.Fragment>
    );
}
