import { createTheme } from '@mui/material/styles';

const datagridScroll = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-virtualScroller': {
            scrollbarWidth: 'thin', // Para navegadores que soportan `scrollbar-width`
            '&::-webkit-scrollbar': {
              width: '8px', // Ancho del scrollbar para Webkit browsers
              height: '8px', // Altura del scrollbar horizontal
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.6)', // Color del thumb del scrollbar
              borderRadius: '4px', // Redondear las esquinas del thumb
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.1)', // Color del track del scrollbar
            },
          },
        },
      },
    },
  },
});

export default datagridScroll;