import React from 'react';
import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import { eachDayOfInterval, format, startOfMonth, endOfMonth } from 'date-fns';

/*
0: 0, // Solo considerar estados 0 (no trabajado)
1: 0, // Solo considerar estados 1 (presente)
2: 0, // Solo considerar estados 2 (ausente)
3: 0, // Solo considerar estados 3 (retirado)
*/
const CalendarioMesAlumno = ({ year, month, diasAsistencia, diasRetirados }) => {
    const valorInicial = new Date(year, month - 1); // Los meses en JavaScript comienzan en 0
    var arrAsistencia = [];
    var arrInasistencia = [];
    var arrRetirado = [];

    //console.log(diasAsistencia)

    const intervalo = {
        start: startOfMonth(new Date(year, month - 1)),
        end: endOfMonth(new Date(year, month - 1))
    };

    const diasRetiradosFiltroEstado = diasRetirados.filter(dia => dia.estado === 3);
    const retirados = diasRetiradosFiltroEstado.map(diaRetirado => diaRetirado.dia);
    //console.log(retirados)

    const asistencia = diasAsistencia.datasets[0].data;
    const inasistencia = diasAsistencia.datasets[1].data;

    // Recorrer cada día del intervalo
    const diasIngles = ["Monday", "Tuesday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    var diaMes = 1;
    eachDayOfInterval(intervalo).forEach(dia => {
        //console.log(dia);
        if (diasIngles.includes(format(dia, 'EEEE'))) {
            if (retirados.includes(diaMes)) {
                arrRetirado.push(diaMes);
                //console.log(diaMes);
            }


        }
        diaMes += 1;
    });

    var diaMes = 1;
    asistencia.map(enumAsistencia => {
        if (enumAsistencia === 1) {
            arrAsistencia.push(diaMes);
        }
        diaMes += 1;
    });

    var diaMes = 1;
    inasistencia.map(enumInasistencia => {
        if (enumInasistencia === 1) {
            arrInasistencia.push(diaMes);
        }
        diaMes += 1;
    });

    /* console.log(arrAsistencia);
    console.log(arrInasistencia);
    console.log(arrRetirado); */


    return (
        <Calendar
            value={valorInicial}
            showNavigation={false}
            tileClassName={({ date }) => {
                const esMismoMes = date.getMonth() === valorInicial.getMonth();
                const esMismoAno = date.getFullYear() === valorInicial.getFullYear();
                const dia = date.getDate();

                if (esMismoMes && esMismoAno) {
                    if (arrInasistencia.includes(dia)) {
                        return 'diaRojo';
                    } else if (arrAsistencia.includes(dia)) {
                        return 'diaVerde';
                    } else if (arrRetirado.includes(dia)) {
                        return 'diaAmarillo';
                    }
                }
            }}
        />
    );
};

export default CalendarioMesAlumno;
