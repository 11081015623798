import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import axios from 'axios';

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Footer from "./views/Footer";
import LoginForm from "./views/Login/LoginForm";

import React, { useContext, useEffect } from "react";

import { ToastContainer } from "react-toastify";

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/theme'; // Importa el tema personalizado

import { AuthContext } from "./contexts/AuthContext";
import { IlumenDataProvider } from "./contexts/IlumenDataContext";
import { GeneralDataProvider } from "./contexts/GeneralDataContext";
import { GoogleMapsProvider } from "./contexts/GoogleMapsContext";
import DashboardApp from "./views/Dashboard/DashboardApp";


import "./App.css";
import AsistenciaTotalColegio from "./components/AsistenciaTotalColegio/AsistenciaTotalColegio";
import DashboardContent from "./views/Dashboard/DashboardContent";
import { InformeIAProvider } from "./contexts/InformeIAContext";

const API_EDUTECH_PROTO = import.meta.env.VITE_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = import.meta.env.VITE_API_EDUTECH_HOST;
const API_EDUTECH_PORT = import.meta.env.VITE_API_EDUTECH_PORT;


function App() {
  const { isAuthenticated, setIsAuthenticated, setPerfilUsuario } = useContext(AuthContext);

  useEffect(() => {
    document.title = import.meta.env.VITE_APP_TITLE || '';

    const checkSession = async () => {
      //const localSessionToken = localStorage.getItem("token");
      //const formtoken = { token: localSessionToken };
      const formtoken = {};
      try {
        const { data } = await axios.post(
          `${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/verify`,
          formtoken,
          {
            withCredentials: true,
          }
        );

        setIsAuthenticated(data.status);
        setPerfilUsuario(data.username);
      } catch (error) {
        setIsAuthenticated(false);
        console.log(error);
      }
    };

    checkSession();

  }, [setIsAuthenticated]);


  if (isAuthenticated === null) {
    return (
      <>
        <Container
          maxWidth="lg"
          sx={{
            marginTop: "100px",
            alignContent: "center",
            textAlign: "center",
            /*background: "red"*/
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Container>
      </>
    );
  }


  return (
    <>
      <Box
        display="block"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ background: "#f4f5f7", color: "#373737" }}
      >
        <Container
          maxWidth={false}
          sx={{
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <Routes>
                {!isAuthenticated && <Route path="/login" element={<LoginForm />} />}
                {isAuthenticated ? (
                  <>
                    <Route path="/asistencia_mensual" element={
                      <>
                        <DashboardApp />
                        <IlumenDataProvider>
                          <InformeIAProvider>
                            <GoogleMapsProvider>
                              <AsistenciaTotalColegio />
                            </GoogleMapsProvider>
                          </InformeIAProvider>
                        </IlumenDataProvider>
                      </>
                    } />

                    <Route path="/" element={
                      <>
                        <DashboardApp />
                        <GeneralDataProvider>
                          <GoogleMapsProvider>
                            <DashboardContent />
                          </GoogleMapsProvider>
                        </GeneralDataProvider>
                      </>
                    } />
                  </>
                ) : (
                  <Route path="/" element={<Navigate to="/login" replace />} />
                )}
                <Route path="/login" element={
                  <>
                    <LoginForm />
                  </>
                } />
              </Routes>
            </BrowserRouter>


          </ThemeProvider>

        </Container>
        <ToastContainer />
      </Box>
      <Footer />
    </>
  );
}

export default App;
