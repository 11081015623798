import { createTheme } from '@mui/material/styles';
const base_color = import.meta.env.VITE_BASE_COLOR;

const theme = createTheme({
  palette: {
    primary: {
      main: `#${base_color}`,
    },
  },
});

export default theme;
