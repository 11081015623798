export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getNombreColegio = async (infoGeneral, id_colegio) => {

    let nombre_colegio = "";
    if (Array.isArray(infoGeneral)) {
        infoGeneral.map((infoColegio) => {
            if (infoColegio.colegio.id_colegio === id_colegio) {
                nombre_colegio = infoColegio.colegio.colegio;
            }

        });
    }

    return nombre_colegio;
}

export const getNombreCurso = async (lista_cursos, id_curso) => {

    let nombre_curso = "";
    if (Array.isArray(lista_cursos)) {
        lista_cursos.map((infoCurso) => {
            if (infoCurso.id_curso === id_curso) {
                nombre_curso = infoCurso.curso;
            }

        });
    }

    return nombre_curso;
}



export const capitalize = (frase) => {
    if (typeof frase !== 'string') {
        // Puedes devolver un valor predeterminado o lanzar un error
        return ''; // Valor predeterminado
        // O lanzar un error:
        // throw new Error('La función capitalize espera una cadena como argumento');
    }
    return frase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const cuentaDias = (nombreDia, listaInasistencia) => {
    var countDias = 0;
    listaInasistencia.map(lstInasistencia => {
        if (nombreDia === lstInasistencia.nombre_dia) {
            //console.log(lstInasistencia);
            countDias += 1;
        }
    })

    return countDias;
}

export const categoriaAsistencia = (asistencia) => {
    var categoria = "";
    var alert = "";

    if (asistencia >= 90 && asistencia <= 100) {
        categoria = "Inasistencia esperada (90% - 100% asist.)";
        alert = "success";
    } else if (asistencia >= 85 && asistencia <= 89) {
        categoria = "Inasistencia reiterada (85% - 89% asist.)";
        alert = "info";
    } else if (asistencia >= 50 && asistencia <= 84) {
        categoria = "Inasistencia grave (50% - 84% asist.)";
        alert = "warning";
    } else if (asistencia >= 0 && asistencia <= 49) {
        categoria = "Inasistencia crítica (0% - 49% asist.)";
        alert = "error";
    }

    return { categoria: categoria, alert: alert };
}


export const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const diferenciaMeses = hoy.getMonth() - nacimiento.getMonth();

    if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < nacimiento.getDate())) {
        edad--;
    }

    return edad;
}


export const fontSize = {
    xl: "16px",
    lg: "16px",
    md: "16px",
    sm: "15px",
    xs: "15px",
}

export const fontSizeTitle = {
    xl: "19px",
    lg: "19px",
    md: "18px",
    sm: "16px",
    xs: "16px",
}

export const fontSizeTable = {
    xl: "12px",
    lg: "12px",
    md: "12px",
    sm: "12px",
    xs: "11px",
}

export const fontSizeDialog = {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "14px",
    xs: "14px",
  }