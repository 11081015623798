import React, { useState, useContext, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { IlumenDataContext } from "../../../../contexts/IlumenDataContext";
import { Alert, Card, CardContent, IconButton, Typography } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { DataGrid } from "@mui/x-data-grid";
import { capitalize, categoriaAsistencia } from "../../../../Utils/utils";

import datagridScroll from '../../../../themes/DataGrid/scroll';

import { ThemeProvider } from '@mui/material/styles';

export default function TablaInasistenciaAlumnosMes({ titulo, fontSize, fontSizeTable, info, handleVerCurso }) {
  const tablaTopCurso = [];

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'inasistencia',
      headerName: 'Inasistencia',
      width: 80,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.inasistencia}%
        </>
      ),
    },
    {
      field: 'opciones',
      headerName: '',
      width: 50,
      editable: false,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="Ver Asistencia Alumnos"
            onClick={() =>
              handleVerCurso(params.row.id_curso)
            }
          >
            <ZoomInIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: 'curso',
      headerName: 'Curso',
      width: 110,
      editable: false,
    },
    {
      field: 'nombre_alumno',
      headerName: 'Nombre Alumno',
      width: 180,
      editable: false,
    },
    {
      field: 'asistencia',
      headerName: 'Estado',
      width: 300,
      editable: false,
      renderCell: (params) => (
        <>
          <Alert severity={params.row.categoria_asistencia.alert}>
            {params.row.categoria_asistencia.categoria}
          </Alert>
        </>
      ),
    },

  ];

  const rows = [];

  if (Object.keys(info).length !== 0) {

    info.forEach((item) => {
      if (item !== null) {

        let objTopCursos = {
          id: `asistencia-inasistencia-mes-${item.id_curso}-${item.id_nomina_alumno}`,
          id_curso: item.id_curso,
          curso: item.curso,
          nombre_alumno: capitalize(item.nombre_alumno),
          inasistencia: item.promedio_ausente,
          asistencia: (100 - parseFloat(item.promedio_ausente)),
          categoria_asistencia: categoriaAsistencia((100 - parseFloat(item.promedio_ausente)))
        };

        rows.push(objTopCursos);
      }

    });
  }
  const hiddenColumns = ['id'];
  rows.sort((a, b) => b.inasistencia - a.inasistencia);

  tablaTopCurso.push(
    <React.Fragment key="asistencia-inasistencia-mes">
      <Card>
        <CardContent>
          <ThemeProvider theme={datagridScroll}>
            <Typography variant='h6' sx={{ fontSize: fontSize }}>{titulo}</Typography>
            <DataGrid
              autoHeight
              autoWidth
              rows={rows}
              columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
              rowHeight={30}
              columnHeaderHeight={40}
              sx={{ fontSize: fontSizeTable }}
            //hideFooter={true}
            />
          </ThemeProvider>
        </CardContent>
      </Card>
    </React.Fragment>
  );
  return tablaTopCurso;
}