import React, { useContext, useRef, useState } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Divider,
  Link,
  Box,
} from '@mui/material';
import { useNavigate } from "react-router-dom";

import logoApp from "../../assets/logo-ilumen.png";
import "./LoginForm.css"

import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../contexts/AuthContext";

const URL_REGISTER = import.meta.env.VITE_URL_REGISTER;
const URL_RESET_PASSWORD = import.meta.env.VITE_URL_RESET_PASSWORD;
const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;


const LoginForm = () => {
  const { isAuthenticated, login } = useContext(AuthContext);

  const navigate = useNavigate();

  const captcha = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaIsDone, setCaptchaIsDone] = useState(true);
  const handleRecaptcha = () => {
    setCaptchaIsDone(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      console.log(captchaIsDone);
      if (!captchaIsDone) {
        return;
      }
      const tokenReCaptcha = captcha.current.getValue();
      const formlogin = { email: email, password: password, tokenReCaptcha: tokenReCaptcha };
      login(formlogin);
    } catch (error) {
      
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }

  };


  if (isAuthenticated) {
    navigate("/");
  }


  return (
    <>
      <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <div style={{ textAlign: 'center' }}>
            <img
              src={logoApp}
              style={{ width: '220px' }}
            />
          </div>
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            Iniciar Sesión
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Correo Electrónico"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              margin="normal"
              sx={{ background: "#fafafa" }}
              size='small'
            />
            <TextField
              fullWidth
              label="Contraseña"
              variant="outlined"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              margin="normal"
              sx={{ background: "#fafafa" }}
              size='small'
            />
            <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
              <ReCAPTCHA
                ref={captcha}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptcha}
                style={{ margin: 'auto' }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              Iniciar Sesión
            </Button>
          </form>
          <Divider style={{ margin: '20px 0', textAlign: 'center' }}>
            O
          </Divider>
          <Typography variant="body2" color="textSecondary" align="center">
            ¿Olvidaste la contraseña?{' '}
            <Link href={URL_RESET_PASSWORD} variant="body2">
              Restablecerla
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" sx={{ pt: "10px" }}>
            ¿No tienes una cuenta?{' '}
            <Link href={URL_REGISTER} target="_blank" variant="body2">
              Regístrate
            </Link>
          </Typography>
        </Paper>
      </Container>



    </>
  );
};

export default LoginForm;
